import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as PlaneYellow } from 'components/checkout-payment/assets/plane-yellow.svg'
import { useTranslation } from 'react-i18next'

export default function Sent() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <PlaneYellow className="h-6 w-6" />
        {t('Invitation sent')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The invitation link has been sent to the customer. They will receive an email prompting them to sign up.'
        )}
      </ActionCard.Body>
    </ActionCard>
  )
}
