import { MerchantClinician, MerchantTreatment } from '@tabeo/ts-types'
import { NormalizedMerchant } from 'types/NormalizedMerchant'
import { getValueForLoanOption } from './components/payment/FinanceRadioCard'
import { getLoanOptionsWithCustomerFacingTerms } from './components/payment/useFinanceOptions'
import { parseMerchantTreatmentId } from './components/treatment/useMerchantTreatments'

export function getMerchantTreatmentById(
  id: string | undefined,
  merchant: NormalizedMerchant
) {
  return merchant.merchantTreatments?.find(
    (mt: MerchantTreatment) => mt.id === parseMerchantTreatmentId(id)
  )
}

export function getMerchantClinicianById(
  id: string | number | undefined,
  merchant: NormalizedMerchant
) {
  return merchant.merchantClinicians?.find(
    (mc: MerchantClinician) => mc.id === Number(id)
  )
}

export function getLoanOptionByPaymentMethod(
  paymentMethod: string | undefined,
  merchant: NormalizedMerchant
) {
  const loanOptions = getLoanOptionsWithCustomerFacingTerms(merchant)

  return loanOptions.find(o => getValueForLoanOption(o) === paymentMethod)
}
