import { GridTable, Heading, Tag, View, themeGet } from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useState } from 'react'
import { useTheme } from 'styled-components'

import Option from './Option'

function ExampleOptions({ ...rest }) {
  const theme = useTheme()
  const [selectedDuration, setSelectedDuration] = useState(12)

  const highlightedOptions = [
    { apr: 0.0, cost: 200_00, fee: 204_00, length: 12 },
    { apr: 0.059, cost: 206_00, fee: 149_00, length: 12 },
    { apr: 0.099, cost: 210_00, fee: 108_00, length: 12 },
    { apr: 0.0, cost: 100_00, fee: 322_00, length: 24 },
    { apr: 0.059, cost: 106_00, fee: 204_00, length: 24 },
    { apr: 0.099, cost: 110_00, fee: 125_00, length: 24 },
  ]

  return (
    <View {...rest}>
      <Heading>
        An implant at {format(2400_00, undefined, 0)} would cost your patient:
      </Heading>
      <View mt={8} mb={[2, 1]}>
        <Tag
          onClick={() => setSelectedDuration(12)}
          cursor="pointer"
          className="-translate-y-1/2"
          bg={selectedDuration === 12 ? 'blue.6' : 'none'}
          style={{ color: themeGet('colors.ink.0')({ theme }) }}
        >
          12 months
        </Tag>
        <Tag
          onClick={() => setSelectedDuration(24)}
          cursor="pointer"
          className="-translate-y-1/2"
          bg={selectedDuration === 24 ? 'blue.6' : 'none'}
          style={{ color: themeGet('colors.ink.0')({ theme }) }}
        >
          24 months
        </Tag>
      </View>
      <GridTable
        templateColumns={['1fr', 'repeat(3, 1fr)']}
        gap={['16px', '20px']}
      >
        {highlightedOptions
          .filter(option => option.length === selectedDuration)
          .map(option => (
            <Option
              key={option.cost}
              apr={option.apr}
              cost={option.cost}
              fee={option.fee}
            />
          ))}
      </GridTable>
    </View>
  )
}

export default ExampleOptions
