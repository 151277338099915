import { Modal, Progressbar, Subheading, View } from '@tabeo/scarf'
import moment from 'moment'
import React, { ComponentPropsWithoutRef, useState } from 'react'
import { useAuth } from 'resources/Auth'
import PricingChangesContext from './Context'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'

export type PricingChangesModalProps = ComponentPropsWithoutRef<
  typeof Modal
> & {
  modalId: string
}

const PricingChangesModal = React.forwardRef<Modal, PricingChangesModalProps>(
  ({ modalId, ...rest }, ref) => {
    const { resource } = useAuth()
    const { patchModalValue } = resource as any

    const [step, setStep] = useState(0)

    const steps = [<Step1 />, <Step2 />, <Step3 />]

    const handleNext = () => {
      setStep((step + 1) % steps.length)
    }

    return (
      <Modal ref={ref} closable={false} onClose={() => setStep(0)} {...rest}>
        {({ isOpen, close }) => {
          const handleRemindMe = () => {
            patchModalValue(modalId, {
              displayAt: moment().add(1, 'day').toISOString(),
            })
            close()
          }

          const handleDontRemindMe = () => {
            patchModalValue(modalId, {
              displayAt: null,
              status: 'accepted',
            })
            close()
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '700px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              pt={[10, 10]}
              pb={[5, 10]}
              px={[4, 10]}
            >
              <View mb={2}>
                <Progressbar
                  percent={Math.round(((step + 1) / steps.length) * 100)}
                />
                <Subheading mt={2} color="ink.3">
                  Step {step + 1}/{steps.length}
                </Subheading>
              </View>
              <PricingChangesContext.Provider
                value={{
                  next: handleNext,
                  remindMe: handleRemindMe,
                  dontRemindMe: handleDontRemindMe,
                }}
              >
                {steps[step]}
              </PricingChangesContext.Provider>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default PricingChangesModal
