import { Button, Modal, Title, View } from '@tabeo/scarf'
import { FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { name, required } from '@tabeo/sharpei/utils/validations'
import { Basket, MerchantClinician } from '@tabeo/ts-types'
import FormError from 'components/Form/FormError'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import { Field } from 'components/nnts/form/Field'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

export type UpsertModalProps = {
  onSubmit: (values: {
    merchantClinicianID?: number
    clinicianName?: string
  }) => Promise<void>
  data?: Basket
  isCliniciansAndTreatmentsEnabled?: boolean
} & React.ComponentProps<typeof Modal>

const UpsertModal = React.forwardRef(
  (
    {
      onSubmit,
      data,
      isCliniciansAndTreatmentsEnabled,
      ...rest
    }: UpsertModalProps,
    ref: any
  ) => {
    const { t } = useTranslation()

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          async function handleSubmit({
            merchantClinician,
            clinicianName,
          }: {
            merchantClinician: MerchantClinician
            clinicianName?: string
          }) {
            try {
              await onSubmit({
                merchantClinicianID: merchantClinician?.id,
                clinicianName,
              })
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <Title mb={5}>
                {data?.clinicianName ? t('Edit clinician') : t('Add clinician')}
              </Title>
              <Form
                onSubmit={handleSubmit}
                initialValues={{
                  merchantClinician: data?.merchantClinician?.id
                    ? data.merchantClinician
                    : undefined,
                  clinicianName: data?.clinicianName,
                }}
                keepDirtyOnReinitialize
                destroyOnUnregister
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    {isCliniciansAndTreatmentsEnabled ? (
                      <Field
                        name="merchantClinician"
                        label="Clinician"
                        component={MerchantClinicianSelect}
                        variant="outline-v2"
                        optional
                        maxWidth="100%"
                      />
                    ) : (
                      <Field
                        name="clinicianName"
                        label="Clinician"
                        placeholder="E.g. Dr. John Smith"
                        component={FFTextInput}
                        validate={[required, name]}
                        autoFocus
                      />
                    )}
                    <div className="mt-6">
                      <Button loading={submitting} mb={4} width="100%">
                        {t('Confirm')}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={close}
                        width="100%"
                      >
                        {t('Close')}
                      </Button>
                    </div>

                    <FormError />
                  </form>
                )}
              </Form>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default UpsertModal
