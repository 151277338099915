import { DatePicker } from '@tabeo/scarf'
import { Button, RadioCards } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { required } from '@tabeo/sharpei/utils/validations'
import { Field } from 'components/nnts/form/Field'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useNewTransactionFlow } from '../provider'
import CardLogos from './CardLogos'

export function BillPaymentOption({ disabled }: { disabled?: boolean }) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()

  return (
    <RadioCards.Option value="bill" disabled={disabled}>
      {({ checked }) => (
        <>
          <div className="flex items-start gap-x-2">
            <RadioCards.OptionDot />
            <div className="flex flex-1 items-start justify-between">
              <div className="space-y-2">
                <div className="font-medium">{t('Scheduled')}</div>
                <CardLogos />
              </div>
              <div className="flex flex-col items-end gap-2">
                {format(state.payment?.price)}
              </div>
            </div>
          </div>
          {checked && (
            <div className="-m-4 mt-4 space-y-5 rounded-b border-t-2 border-tabeo-primary-3 bg-white p-5 md:-m-5 md:mt-5">
              <div className="flex flex-col items-start gap-4 md:flex-row">
                <Field
                  name="scheduledAt"
                  label={t('Payment date')}
                  component={DatePicker}
                  variant="outline-v2"
                  clearable
                  width={['100%']}
                  alignPopupToTop
                  validate={required}
                  datePickerProps={{
                    modifiers: {
                      disabled: {
                        before: moment().add(1, 'day').toDate(),
                      },
                    },
                  }}
                  description={t(
                    `We'll charge the patient at 6am on this date.`
                  )}
                />
                <div className="w-full" />
              </div>
            </div>
          )}
        </>
      )}
    </RadioCards.Option>
  )
}

export function BillActions() {
  const { form, flowSteps, currentIndex } = useNewTransactionFlow()
  const { submitting } = form.current?.getState() || {}
  const { t } = useTranslation()

  const { isLast } = flowSteps[currentIndex]
  if (!isLast) {
    return (
      <Button
        variant="primary"
        className="w-full"
        type="submit"
        onClick={form.current?.submit}
      >
        {t('Continue')}
      </Button>
    )
  }

  return (
    <Button
      variant="primary"
      className="w-full"
      type="submit"
      onClick={form.current?.submit}
      loading={submitting}
    >
      {t('Send payment request')}
    </Button>
  )
}
