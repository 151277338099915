import { t } from 'i18n'
import { useMerchant } from 'resources/Merchant'

export const dentalTitleOptionsPOT = [
  t('Bridge'),
  t('Composite bonds'),
  t('Crown'),
  t('Denture'),
  t('Fixed braces'),
  t('Implant and crown'),
  t('Invisalign'),
  t('Six Month Smile aligner'),
  t('ClearCorrect'),
  t('Root canal'),
  t('Teeth whitening'),
  t('Veneers'),
  t('Other'),
]

export const dentalTitleOptionsPN = [
  t('Bridge'),
  t('Checkup'),
  t('Composite bonds'),
  t('Consultation'),
  t('Crown'),
  t('Denture'),
  t('Filling'),
  t('Fixed braces'),
  t('Dental hygiene'),
  t('Implant and crown'),
  t('Invisalign'),
  t('Six Month Smile aligner'),
  t('ClearCorrect'),
  t('Root canal'),
  t('Teeth whitening'),
  t('Veneers'),
  t('Other'),
]

export const opticalTitleOptions = [
  t('LASIK surgery'),
  t('Cataract surgery'),
  t('New glasses'),
  t('Hearing aid'),
  t('Other'),
]

export const cosmeticTitleOptions = [
  t('Breast Enlargement'),
  t('Breast Uplift'),
  t('Breast Reduction'),
  t('Breast Variable'),
  t('Fat Removal'),
  t('Nose Reshaping'),
  t('Labiaplasty'),
  t('Tummy Tuck'),
  t('Other'),
]

export const hospitalTitleOptions = [
  t('Hip replacement'),
  t('Knee replacement'),
  t('Breast augmentation'),
  t('Rhinoplasty'),
  t('Liposuction'),
  t('Other'),
]

export const vetTtitleOptions = [
  t('Chronic Gastrointestinal Disease'),
  t('Chronic Kidney Disease'),
  t('Chronic Cough'),
  t('Cruciate Surgery'),
  t('Collapse/Syncope'),
  t('Cushings Disease'),
  t('Dental Disease'),
  t('Diarrhoea and Vomiting'),
  t('Diabetes Mellitus'),
  t('Fracture Repair'),
  t('Heart Murmur Investigation'),
  t('Inappetance'),
  t('Lameness Investigation'),
  t('Lump Removal'),
  t('Neoplasia'),
  t('Ocular Surgery'),
  t('Patellar Surgery'),
  t('Polyuria/Polydipisa'),
  t('Respiratory Distress'),
  t('Spinal Surgery'),
  t('Other'),
]

const SDC = [
  t('SmileDirectClub Clear Aligners'),
  t('SmileDirectClub Nighttime Clear Aligners'),
  t('FastTrack SmileDirectClub Clear Aligners'),
  t('FastTrack SmileDirectClub Nighttime Clear Aligners'),
  t('Other'),
]

const drSmileIt = [
  t('Treatment with aligners (package S)'),
  t('Treatment with aligners (package M)'),
  t('Treatment with aligners (package L)'),
  t('Other'),
]

export function getTitleOptionsBySector(
  type,
  transactionType = 'pay_over_time'
) {
  let options
  switch (type) {
    case 'dental_services':
      options =
        transactionType === 'pay_over_time'
          ? dentalTitleOptionsPOT
          : dentalTitleOptionsPN
      break
    case 'optical_care':
      options = opticalTitleOptions
      break
    case 'cosmetic_surgery':
      options = cosmeticTitleOptions
      break
    case 'private_hospital':
      options = hospitalTitleOptions
      break
    case 'veterinary_services':
      options = vetTtitleOptions
      break
    case 'sdc':
      options = SDC
      break
    case 'drSmileIt':
      options = drSmileIt
      break
    default:
      return null
  }
  return options.map(i => ({ label: i }))
}

export function useTransactionTitles(transactionType = 'pay_over_time') {
  const { data } = useMerchant()
  if (!data) {
    return []
  }
  const { sector, isSDC, isDrSmileIt } = data
  return getTitleOptionsBySector(
    isSDC ? 'sdc' : isDrSmileIt ? 'drSmileIt' : sector,
    transactionType
  )
}
