import { Portal } from '@headlessui/react'
import { Modal } from '@tabeo/scarf'
import Notification from 'components/Notifications/DatoNotifications/Notification'
import { ComponentPropsWithRef, useEffect, useRef } from 'react'
import { useAuth } from 'resources/Auth'
import { useMerchant } from 'resources/Merchant'
import { twMerge } from 'tailwind-merge'
import PricingChangesModal from './Modal'

export type PricingChangesProps = ComponentPropsWithRef<'div'>

function PricingChanges({ className }: PricingChangesProps) {
  const modalId = 'pricingUpdate2024-1'

  const modal = useRef<Modal>(null)
  const { data } = useAuth()
  const { data: merchant } = useMerchant()

  const modals = data?.user?.metadata?.modals

  useEffect(() => {
    const m = modals?.[modalId]

    if (!m) {
      return
    }

    if (
      new Date(m.displayAt) < new Date() &&
      !['accepted', 'expired'].includes(modals[modalId]?.status)
    ) {
      setTimeout(() => {
        modal.current?.open()
      }, 200)
    }
  }, [modals, modalId])

  if (
    merchant?.countryCode !== 'gb' ||
    !modals?.[modalId] ||
    ['accepted', 'expired'].includes(modals[modalId]?.status)
  ) {
    return null
  }

  return (
    <div className={twMerge('mb-6', className)}>
      <Portal>
        <PricingChangesModal modalId={modalId} ref={modal} />
      </Portal>
      <Notification
        data={{
          id: modalId,
          variant: 'info',
          content: '',
          conditions: {},
        }}
      >
        Update on Subsidy Fees.{' '}
        <button
          type="button"
          className="underline"
          onClick={() => modal.current?.open()}
        >
          Learn more
        </button>
      </Notification>
    </div>
  )
}

export default PricingChanges
