import { children } from '@tabeo/sharpei/utils/propTypes'
import dino from 'assets/dino.png'
import Layout from 'components/Layout'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Failed from './Failed'

function getErrorType(err) {
  const isNetworkError = get(err, 'reason.message', false) === 'Network Error'
  const isNotFound = get(err, 'reason.response.status', false) === 404
  const errorType = isNetworkError
    ? 'network'
    : isNotFound
    ? 'not_found'
    : 'internal'
  return errorType
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
    this.img = new Image()
    this.img.src = dino
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleJavaScriptErrors)
    window.addEventListener('error', this.handleJavaScriptErrors)
  }

  componentDidUpdate({ location: { key: oldKey } }) {
    const {
      location: { key },
    } = this.props
    if (key !== oldKey) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, info) {
    const { theme } = this.props
    const errorType = getErrorType(error)
    // Display fallback UI
    this.setState({ hasError: true, type: errorType, error })
    // Log the error to an error reporting service
    Raven.captureException(error, {
      extra: { ...info, theme },
    })
  }

  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.handleJavaScriptErrors
    )
    window.removeEventListener('error', this.handleJavaScriptErrors)
  }

  handleJavaScriptErrors = error => {
    if (JSON.stringify(error) === JSON.stringify({ isTrusted: true })) {
      // Firefox iOS error, from webkit probably
      return
    }
    const errorType = getErrorType(error)
    this.setState({ hasError: true, type: errorType, error })
    Raven.captureException(error, {
      extra: get(error, 'reason.response', null),
    })
  }

  render() {
    const { hasError, type, error } = this.state
    const { children } = this.props

    if (hasError) {
      // Render fallback UI
      return (
        <Layout bg={['white', 'sky.2']}>
          <Failed type={type} error={error} />
        </Layout>
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  location: PropTypes.object.isRequired,
  theme: PropTypes.object,
  children,
}

export default withTheme(withRouter(ErrorBoundary))
