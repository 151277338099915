'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var tslib_es6 = require('../tslib.es6-1ef680b2.js');
require('i18next');
var index = require('../i18n/index.js');
require('../config.js');
require('raven-js');
require('axios');
var debounce = require('./debounce.js');
var parseIban = require('./parseIban.js');
var pca = require('./pca.js');
var moment = _interopDefault(require('moment'));

var required = function (value) {
    return (!value && value !== 0 && value !== false) || value === "{}"
        ? index.t('Field is required')
        : undefined;
};
var number = function (value) {
    return !/^-?\d+\.?\d*$/.test(value) ? index.t('Field must be a number') : undefined;
};
var name = function (value) {
    var regex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
    if (!regex.test(value)) {
        return index.t('Invalid name format');
    }
};
var email = function (string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(string)) {
        return index.t('Invalid email address format');
    }
};
// eslint-disable-next-line arrow-body-style
var address = function (value) {
    return !value || !value.Label ? index.t('Field is required') : undefined;
};
var isDateValid = function (date) {
    var d = new Date(date);
    if (Object.prototype.toString.call(d) === '[object Date]') {
        if (!Number.isNaN(Number(d.getTime()))) {
            return true;
        }
    }
};
var date = function (validator) {
    return function (date) {
        var valid = isDateValid(date);
        if (valid) {
            var pastTime = new Date(date).getTime();
            var now = new Date().getTime();
            var ellapsed = now - pastTime;
            var ellapsedDays = ellapsed / 1000 / 60 / 60 / 24;
            var ellapsedYears = ellapsedDays / 365;
            return validator({ ellapsed: ellapsed, ellapsedDays: ellapsedDays, ellapsedYears: ellapsedYears });
        }
        else {
            return index.t('Invalid date');
        }
    };
};
var simpleMemoize = function (fn) {
    var lastArg;
    var lastResult;
    return function (arg) {
        if (!lastArg || arg !== lastArg) {
            lastArg = arg;
            lastResult = fn(arg);
        }
        return lastResult;
    };
};
var emailPCA = simpleMemoize(debounce.default(function (value) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var _a, error, ResponseCode;
    return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, pca.validateEmail(value)];
            case 1:
                _a = _b.sent(), error = _a.Error, ResponseCode = _a.ResponseCode;
                if (!error && ResponseCode === 'Invalid') {
                    return [2 /*return*/, index.t('Email address is not valid')];
                }
                return [2 /*return*/];
        }
    });
}); }, 800));
var phone = function (value) {
    if (value === void 0) { value = ''; }
    return value.length < 11 ? index.t('Invalid phone number format') : undefined;
};
var mobilePhonePCA = simpleMemoize(debounce.default(function (value) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var syntaxError, _a, error, IsValid, NumberType;
    return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                syntaxError = phone(value);
                if (syntaxError) {
                    return [2 /*return*/, syntaxError];
                }
                return [4 /*yield*/, pca.validatePhoneNumber(value)];
            case 1:
                _a = _b.sent(), error = _a.Error, IsValid = _a.IsValid, NumberType = _a.NumberType;
                if (!error && IsValid === 'No') {
                    return [2 /*return*/, index.t('Phone number is not valid')];
                }
                if (!['Mobile', 'Unknown'].includes(NumberType)) {
                    return [2 /*return*/, index.t('Must be a mobile number')];
                }
                return [2 /*return*/];
        }
    });
}); }, 800));
var phonePCA = simpleMemoize(debounce.default(function (value) { return tslib_es6.__awaiter(void 0, void 0, void 0, function () {
    var syntaxError, _a, error, IsValid;
    return tslib_es6.__generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                syntaxError = phone(value);
                if (syntaxError) {
                    return [2 /*return*/, syntaxError];
                }
                return [4 /*yield*/, pca.validatePhoneNumber(value)];
            case 1:
                _a = _b.sent(), error = _a.Error, IsValid = _a.IsValid;
                if (!error && IsValid === 'No') {
                    return [2 /*return*/, index.t('Phone number is not valid')];
                }
                return [2 /*return*/];
        }
    });
}); }, 800));
var minValue = function (min) { return function (value) {
    return (value || value === 0) && value < min
        ? index.t('Must be at least or equat to {{min}}', { min: min })
        : undefined;
}; };
var maxValue = function (max) { return function (value) {
    return (value || value === 0) && value > max
        ? index.t("Must be less or equal to {{max}}", { max: max })
        : undefined;
}; };
var valueRange = function (min, max, customError) { return function (value) {
    var error = customError || index.t('Must be between {{min}} and {{max}}', { min: min, max: max });
    if (value < min) {
        return error;
    }
    if (value > max) {
        return error;
    }
    return undefined;
}; };
var length = function (target) { return function (value) {
    return value.length !== target
        ? index.t("Must be {{target}} characters", { target: target })
        : undefined;
}; };
var minLength = function (min) { return function (value) {
    return value && value.length < min
        ? index.t("Must be {{min}} characters or more", { min: min })
        : undefined;
}; };
var maxLength = function (max) { return function (value) {
    return value && value.length > max
        ? index.t("Must be {{max}} characters or less", { max: max })
        : undefined;
}; };
var lengthRange = function (min, max, message) { return function (value) {
    var error = message ||
        index.t("Length must be between {{min}} and {{max}} characters", { min: min, max: max });
    if (value.length < min) {
        return error;
    }
    if (value.length > max) {
        return error;
    }
    return undefined;
}; };
var age = function (min, max, errorMessage, unit) {
    if (unit === void 0) { unit = 'years'; }
    return function (value) {
        var birthdate = moment(value);
        var yearDiff = moment().diff(birthdate, unit);
        if (yearDiff < min) {
            return errorMessage || index.t("You must be over {{min}} to continue", { min: min });
        }
        if (yearDiff > max) {
            return (errorMessage ||
                index.t("You must be under {{max}} {{unit}} old to continue", { max: max, unit: unit }));
        }
        return undefined;
    };
};
var onlyContainsLetters = function (value) {
    return !/^[a-z]+$/i.test(value) ? index.t('Invalid input') : undefined;
};
var containsNumber = function (value) {
    return !value.match(/\d+/g) ? index.t('Invalid input') : undefined;
};
var containsUpperCase = function (value) {
    return !value.match(/(?=.*[A-Z])+/g) ? index.t('Invalid input') : undefined;
};
var containsLowerCase = function (value) {
    return !value.match(/(?=.*[a-z])+/g) ? index.t('Invalid input') : undefined;
};
var password = function (value) {
    if (value === void 0) { value = ''; }
    return !containsNumber(value) &&
        !containsLowerCase(value) &&
        !containsUpperCase(value) &&
        value.length > 7
        ? undefined
        : index.t('Invalid password');
};
var regex = function (re, _a) {
    var _b = _a === void 0 ? {} : _a, errorMessage = _b.errorMessage;
    return function (value) {
        if (!re.test(value)) {
            return errorMessage || index.t('Invalid input');
        }
    };
};
var ibanNL = function (v) { return regex(/^NL\d{2}[A-Z]{4}\d{10}$/)(v); };
var ibanFR = function (v) {
    return regex(/^FR\d{2}\d{5}\d{5}[A-Z0-9]{11}\d{2}$/)(v);
};
var ibanIT = function (v) {
    return regex(/^IT\d{2}[A-Z0-9]{1}\d{5}\d{5}\d{12}$/)(v);
};
var iban = function (v) {
    var result = parseIban.default(v);
    if (!result) {
        return index.t('Invalid input');
    }
};

exports.address = address;
exports.age = age;
exports.containsLowerCase = containsLowerCase;
exports.containsNumber = containsNumber;
exports.containsUpperCase = containsUpperCase;
exports.date = date;
exports.email = email;
exports.emailPCA = emailPCA;
exports.iban = iban;
exports.ibanFR = ibanFR;
exports.ibanIT = ibanIT;
exports.ibanNL = ibanNL;
exports.isDateValid = isDateValid;
exports.length = length;
exports.lengthRange = lengthRange;
exports.maxLength = maxLength;
exports.maxValue = maxValue;
exports.minLength = minLength;
exports.minValue = minValue;
exports.mobilePhonePCA = mobilePhonePCA;
exports.name = name;
exports.number = number;
exports.onlyContainsLetters = onlyContainsLetters;
exports.password = password;
exports.phone = phone;
exports.phonePCA = phonePCA;
exports.regex = regex;
exports.required = required;
exports.valueRange = valueRange;
