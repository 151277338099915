import './i18n/init'
import '@tabeo/scarf/dist/index.css'
import { configure } from '@tabeo/sharpei/config'
import '@tabeo/sharpei/polyfills'
import ravenSetup from '@tabeo/sharpei/ravenSetup'
import config from 'config'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import './assets/tailwind.css'

moment.locale(config.LOCALE)

configure(config)
ravenSetup('https://2b00c6c3e6174f74979639805efd5e61@sentry.io/241765')

const rootEl = document.getElementById('root')
ReactDOM.render(<App />, rootEl)
