import {
  Button,
  ButtonGroup,
  Caption,
  GridTable,
  GridTableRow,
  Subheading,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { usePricingChangesContext } from '../Context'
import illustration from '../assets/1.png'
import Cell from './Step2/Cell'
import HeaderCell from './Step2/HeaderCell'

function Step1() {
  const { next, remindMe } = usePricingChangesContext()

  return (
    <View textAlign="center">
      <View
        as="img"
        src={illustration}
        width={['132px', '200px']}
        height={['120px', '180px']}
        mx="auto"
      />
      <Title mt={6}>Reduction of Subsidy Fees</Title>
      <Text mt={5} textAlign="left" fontSize={1}>
        Tabeo is <b>reducing its subsidy fees</b> as recent inflation data led
        to a significant reduction in UK benchmark rates in the last 3 months.
        The market participants expect the Bank of England to lower its base
        rate over the next 12 months.
      </Text>
      <View overflow="auto" mt={6}>
        <GridTable
          templateColumns={[
            'minmax(120px, auto) minmax(92px, auto) minmax(92px, auto)',
            'minmax(206px, auto) 206px 206px',
          ]}
          border={1}
          borderRadius={1}
          overflow="hidden"
          minWidth="300px"
          maxWidth="620px"
          mx="auto"
        >
          <GridTableRow>
            <HeaderCell display="flex" alignItems="end">
              <Subheading ml={2} textAlign="left" color="blue.1">
                Date
              </Subheading>
            </HeaderCell>
            <HeaderCell>
              <Subheading
                color="blue.1"
                display={['flex', 'block']}
                flexDirection="column"
              >
                <span>SONIA</span> <span>(2 years)</span>
              </Subheading>
            </HeaderCell>
            <HeaderCell>
              <Subheading
                color="blue.1"
                display={['flex', 'block']}
                flexDirection="column"
              >
                <span>UK inflation</span> <span>(CPI)</span>
              </Subheading>
            </HeaderCell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                December 2022
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">4.4%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">10.7%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell odd="true">
              <Caption ml={2} textAlign="left" color="ink.0">
                March 2023
              </Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">4.2%</Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">10.4%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                June 2023
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">5.8%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">8.7%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell odd="true">
              <Caption ml={2} textAlign="left" color="ink.0">
                September 2023
              </Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">5.1%</Caption>
            </Cell>
            <Cell odd="true">
              <Caption color="ink.0">6.7%</Caption>
            </Cell>
          </GridTableRow>
          <GridTableRow>
            <Cell>
              <Caption ml={2} textAlign="left" color="ink.0">
                December 2023
              </Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">4.0%</Caption>
            </Cell>
            <Cell>
              <Caption color="ink.0">3.9%</Caption>
            </Cell>
          </GridTableRow>
        </GridTable>
      </View>
      <Text mt={6} textAlign="left" fontSize={1}>
        Since our last pricing change in September, the 2 year SONIA rate
        decreased by about 0.80%. Our subsidy fee adjustment is not directly
        linked to the benchmark rate but set by our lending partners based on
        their funding costs and expected losses.
        <br />
        <br />
        Tabeo is committed to remaining competitive. We will{' '}
        <b>review our subsidy fees</b> regularly over the next 12 months.
      </Text>
      <ButtonGroup width="100%" mt={10}>
        <Button variant="secondary" width="100%" onClick={remindMe}>
          Remind me later
        </Button>
        <Button width="100%" onClick={next}>
          Next
        </Button>
      </ButtonGroup>
    </View>
  )
}

export default Step1
