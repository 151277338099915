import {
  Button,
  Checkbox,
  ConditionalFields,
  CurrencyInput,
  DatePicker,
  Flex,
  Heading,
  NativeSelect,
  PhoneInput,
  Prompt,
  Radio,
  Switch,
  TextInput,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  lengthRange,
  mobilePhonePCA,
  name,
  phone,
  required,
  valueRange,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import MerchantTreatmentSelect, {
  OTHER_VALUES,
} from 'components/MerchantTreatmentSelect'
import config from 'config'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import PropTypes from 'prop-types'
import React from 'react'
import { Field as BaseField, Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import normalize from './paymentFormNormalizer'
import { useTransactionTitles } from './transactionTitleOptionsBySector'

const lengthRangeFiveHundred = lengthRange(5, 100)
const { CURRENCY } = config
// TODO: check currency
const defaultInitialValues = {
  type: 'pay_now',
  treatmentAlreadyPrivided: true,
  currency: CURRENCY,
}

class PaymentForm extends React.Component {
  constructor(props) {
    super(props)

    this.formCache = React.createRef()
    this.formCache.current = {}
  }

  normalize = ({ merchantTreatment, merchantClinician, ...values }) => {
    const title = merchantTreatment?.treatment?.name || values.title
    const otherTitle = OTHER_VALUES.includes(title)
      ? values.otherTitle
      : undefined

    return normalize({
      ...values,
      title,
      otherTitle,
      merchantTreatmentID: merchantTreatment?.id,
      merchantTreatment,
      merchantClinicianID: merchantClinician?.id,
      merchantClinician,
    })
  }

  handleSubmit = async ({ treatmentCategory, ...values }) => {
    const { onSubmit, successModal, t } = this.props
    try {
      const normalizedValues = this.normalize({
        ...values,
        treatmentCategory,
      })
      const transaction = await onSubmit({
        ...normalizedValues,
        title: normalizedValues.otherTitle || normalizedValues.title,
      })
      successModal.current.open({
        ...values,
        ...transaction,
      })
    } catch (e) {
      const consumerError = e.response.data.data.consumer?.[0]

      if (consumerError === 'Consumer is registered in a different country.') {
        return {
          formError: t('Consumer is registered in a different country.'),
        }
      }

      return formError(e)
    }
  }

  priceValidator = value => {
    const { t } = this.props
    return valueRange(
      1000,
      2000000,
      t(`Please specify an amount between {{min}} and {{max}}`, {
        min: format(1000),
        max: format(2000000),
      })
    )(value)
  }

  render() {
    const {
      onFormChange,
      onSubmit,
      successModal,
      initialValues,
      titleOptions,
      merchant,
      t,
      ...rest
    } = this.props
    const { isRetail, isHealthcare, isVet } = merchant
    const titleLabel = isHealthcare
      ? t('Treatment')
      : isRetail
      ? t('Product')
      : t('Transaction name')

    const {
      flags: {
        is_cooling_off_period_capable: isCoolingOffPeriodCapable,
        is_cooling_off_period_waiver_capable:
          isCoolingOffPeriodWaiverCapable = true,
        is_clinicians_treatments_enabled: isCliniciansTreatmentsEnabled,
      },
      sector,
    } = merchant

    const treatmentCategories = [...getCategoriesBySector(sector), t('Other')]

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{
          ...initialValues,
          person: initialValues?.clinicianName,
          ...defaultInitialValues,
        }}
        mutators={{
          setFieldValue: (args, state, tools) => {
            const [field, value] = args
            tools.changeValue(state, field, () => value)
          },
        }}
      >
        {({
          handleSubmit,
          form: { reset, resetFieldState, getRegisteredFields },
          submitting,
          valid,
          validating,
          values: {
            title,
            merchantTreatment,
            treatmentAlreadyPrivided,
            skipInitialPaymentOptionsEmail,
          },
          form: {
            mutators: { setFieldValue },
          },
        }) => {
          return (
            <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
              <form
                onSubmit={async e => {
                  const error = await handleSubmit(e)
                  if (!error && valid) {
                    reset()

                    // https://github.com/final-form/final-form/issues/317
                    // reset() doesn't clean some metadata
                    getRegisteredFields().forEach(field => {
                      // Handle conditional fields
                      if (getRegisteredFields().includes(field)) {
                        resetFieldState(field)
                      }
                    })
                  }
                }}
              >
                <FormSpy
                  onChange={change => {
                    onFormChange({
                      ...change,
                      values: {
                        ...this.normalize(change.values),
                        clinicianName: change.values.person,
                      },
                    })
                  }}
                />
                <View p={[2, 7]} pb={[0, 2]} borderBottom={1}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Transaction')}
                  </Heading>

                  {isHealthcare && isCliniciansTreatmentsEnabled && (
                    <Field
                      name="merchantClinician"
                      label={
                        <Prompt mb={1} fontWeight="semibold">
                          <Trans>
                            Clinician{' '}
                            <Prompt display="inline" color="ink.2">
                              (optional)
                            </Prompt>
                          </Trans>
                        </Prompt>
                      }
                      component={MerchantClinicianSelect}
                      variant="outline"
                      maxWidth="100%"
                    />
                  )}

                  {isCliniciansTreatmentsEnabled ? (
                    <>
                      <Field
                        name="merchantTreatment"
                        label={titleLabel}
                        component={MerchantTreatmentSelect}
                        variant="outline"
                        maxWidth="100%"
                        validate={required}
                      />
                      <FormSpy
                        subscription={{ values: true, active: true }}
                        onChange={p => {
                          if (p.active === 'merchantTreatment') {
                            const { merchantTreatment: mt } = p.values

                            if (
                              mt?.defaultPrice !== null &&
                              this.formCache.current.price !== mt?.defaultPrice
                            ) {
                              setFieldValue('price', undefined)
                              setTimeout(() => {
                                setFieldValue('price', mt?.defaultPrice)
                              }, 100)
                            }

                            this.formCache.current.price =
                              mt?.defaultPrice || undefined
                          }
                        }}
                      />
                      {OTHER_VALUES.includes(
                        merchantTreatment?.treatment.name
                      ) && (
                        <ConditionalFields>
                          <Field
                            name="otherTitle"
                            label={t('Specify name')}
                            variant="outline"
                            clearable
                            placeholder={t('Type your answer here...')}
                            component={TextInput}
                            maxWidth="100%"
                            validate={[required, lengthRangeFiveHundred]}
                          />
                          {!!treatmentCategories.length && (
                            <Field
                              name="treatmentCategory"
                              label={t('Specify category')}
                              component={NativeSelect}
                              placeholder={t('Select from the list...')}
                              options={treatmentCategories.map(o => ({
                                value: o,
                                label: o,
                              }))}
                              maxWidth="100%"
                              validate={required}
                            />
                          )}
                        </ConditionalFields>
                      )}
                    </>
                  ) : titleOptions ? (
                    <>
                      <Field
                        name="title"
                        label={titleLabel}
                        variant="outline"
                        clearable
                        placeholder={t('Select from the list...')}
                        component={NativeSelect}
                        options={titleOptions.map(o => ({
                          ...o,
                          value: o.label,
                        }))}
                        maxWidth="100%"
                        validate={required}
                      />
                      {title === t('Other') && (
                        <Field
                          name="otherTitle"
                          variant="outline"
                          clearable
                          placeholder={t('Please specify...')}
                          component={TextInput}
                          maxWidth="100%"
                          validate={[required, lengthRangeFiveHundred]}
                        />
                      )}
                    </>
                  ) : (
                    <Field
                      name="title"
                      variant="outline"
                      clearable
                      label={titleLabel}
                      placeholder={t('E.g.Item name')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, lengthRangeFiveHundred]}
                    />
                  )}

                  <Flex
                    mb={5}
                    flexDirection={['column', 'row']}
                    alignItems={['stretch', 'center']}
                    justifyContent={['flex-start', 'space-between']}
                  >
                    {isVet && (
                      <Field
                        containerProps={{ mb: [5, 0], mr: [0, 7], flex: 1 }}
                        label={t('Pet name')}
                        name="petName"
                        variant="outline"
                        clearable
                        placeholder={t('E.g. Spot')}
                        component={TextInput}
                        maxWidth="100%"
                        minWidth={['auto', '220px']}
                        validate={[required]}
                      />
                    )}
                    <Field
                      containerProps={{ mb: 0, flex: 1 }}
                      name="price"
                      variant="outline"
                      clearable
                      maxWidth="100%"
                      label={
                        <Prompt mb={1} fontWeight="semibold">
                          <Trans>
                            Price{' '}
                            <Prompt display="inline" color="ink.2">
                              (including VAT)
                            </Prompt>
                          </Trans>
                        </Prompt>
                      }
                      component={CurrencyInput}
                      validate={[required, this.priceValidator]}
                    />
                  </Flex>

                  {isHealthcare && !isCliniciansTreatmentsEnabled && (
                    <Field
                      name="person"
                      variant="outline"
                      clearable
                      label={t('Clinician')}
                      component={TextInput}
                      placeholder={t('E.g. Dr. John Smith')}
                      maxWidth="100%"
                    />
                  )}

                  {!isRetail && (
                    <>
                      {isCoolingOffPeriodCapable &&
                        isCoolingOffPeriodWaiverCapable && (
                          <Flex mb={6}>
                            <Prompt>{t('Treatment already provided?')}</Prompt>
                            <BaseField
                              name="treatmentAlreadyPrivided"
                              component={Switch}
                              labelOn={t('YES')}
                              labelOff={t('NO')}
                            />
                          </Flex>
                        )}

                      {!treatmentAlreadyPrivided && (
                        <Field
                          name="treatmentStartDate"
                          label={t('Expected treatment date')}
                          variant="outline"
                          clearable
                          width="100%"
                          maxWidth="100%"
                          component={DatePicker}
                          alignPopupToTop
                          validate={required}
                          datePickerProps={{
                            modifiers: {
                              disabled: {
                                before: new Date(),
                              },
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </View>

                <View p={[2, 7]} pb={[2, 6]}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Patient')}
                  </Heading>

                  <Field
                    name="consumerGender"
                    label={t('Title')}
                    width={['100%', '50%']}
                    validate={required}
                    component={Radio}
                    horizontal
                    items={[
                      { label: t('Mr'), value: 'm' },
                      { label: t('Ms'), value: 'f' },
                    ]}
                    containerProps={{
                      mr: [0, 5],
                    }}
                    renderItem={p => <TabbedRadioItem {...p} />}
                  />
                  <Flex
                    alignItems={['stretch', 'flex-start']}
                    flexDirection={['column', 'row']}
                  >
                    <Field
                      name="consumerFirstName"
                      variant="outline"
                      clearable
                      label={t('First name')}
                      placeholder={t('E.g. John')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                      containerProps={{
                        flex: '1',
                        mr: [0, 5],
                        maxWidth: ['auto', '244px'],
                      }}
                    />
                    <Field
                      name="consumerLastName"
                      variant="outline"
                      clearable
                      label={t('Last name')}
                      placeholder={t('E.g. Smith')}
                      component={TextInput}
                      maxWidth="100%"
                      containerProps={{
                        flex: '1',
                      }}
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                    />
                  </Flex>

                  <Flex
                    alignItems={['stretch', 'flex-start']}
                    flexDirection={['column', 'row']}
                    mb={7}
                  >
                    <Field
                      data-no-track
                      name="consumerEmail"
                      variant="outline"
                      clearable
                      label={t('Email address')}
                      placeholder={t('E.g. john@email.co.uk')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, email, emailPCA]}
                      containerProps={{
                        flex: '1',
                        mr: [0, 5],
                        mb: 0,
                      }}
                    />

                    <Field
                      data-no-track
                      label={t('Mobile number')}
                      component={PhoneInput}
                      name="consumerPhoneNumber"
                      variant="outline"
                      clearable
                      maxWidth="100%"
                      validate={[required, phone, mobilePhonePCA]}
                      containerProps={{
                        flex: '1',
                        mb: 0,
                      }}
                    />
                  </Flex>

                  {isRetail && (
                    <Field
                      name="skipInitialPaymentOptionsEmail"
                      component={Checkbox}
                      parse={v => !v}
                      format={v => !v}
                    >
                      {t('Send request by sms and email')}
                    </Field>
                  )}

                  <Button
                    width="100%"
                    loading={submitting}
                    disabled={validating}
                  >
                    {isRetail && skipInitialPaymentOptionsEmail
                      ? t('Create payment request')
                      : t('Send payment request')}
                  </Button>
                  <FormError />
                </View>
              </form>
            </View>
          )
        }}
      </Form>
    )
  }
}

PaymentForm.propTypes = {
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  successModal: PropTypes.object,
  initialValues: PropTypes.object,
  merchant: PropTypes.object,
  titleOptions: PropTypes.array,
  titleLabel: PropTypes.string.isRequired,
  t: PropTypes.func,
}

export default function (props) {
  const titleOptions = useTransactionTitles('pay_now')
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()

  return (
    <PaymentForm
      {...props}
      titleOptions={titleOptions}
      merchant={merchant}
      t={t}
    />
  )
}
