import { Portal } from '@headlessui/react'
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid'
import { Modal, useMobile } from '@tabeo/scarf'
import { Button, Tooltip } from '@tabeo/scarf2'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import { FINANCE_MIN_PRICE, MAX_PAYMENT_AMOUNT } from 'config'
import { AnimatePresence, motion } from 'framer-motion'
import {
  AmortizationCalculation,
  ExtendedLoanOption,
  LoanOptionWithRiskProfile,
} from 'pages/NNTS/types'
import ApplyWithClientModal from 'pages/NewTransaction/ApplyWithClientModal'
import { useEffect, useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useNewTransactionFlow } from '../provider'
import FinanceDetailsModal from './FinanceDetailsModal'
import {
  FinanceRadioCardOption,
  getValueForLoanOption,
} from './FinanceRadioCard'
import useFinanceOptions, {
  CUTOFF_PRICE_ABOVE_12_MONTHS,
  isSameLoanOption,
} from './useFinanceOptions'

async function fetchLoanOptionCalculations(
  price: number,
  loanOptions: LoanOptionWithRiskProfile[]
) {
  const payload = loanOptions.reduce(
    (mem, item) => {
      mem[item.numberOfMonths] = {
        numberOfPayments: item.numberOfMonths,
        interestRate: item.interestRate,
        rebateDays: item.rebateDays,
        price,
        maxLoanAmount: price,
        firstPaymentAmount:
          item.profile.name === 'tabeo_plus' ? price * 0.2 : undefined,
      }
      return mem
    },
    {} as {
      [key: number]: any
    }
  )

  return api.post(`/utilities/amortize`, payload)
}

function is12Months(loanOption: ExtendedLoanOption) {
  return loanOption.numberOfMonths === 12
}

export function FinancePaymentOptions() {
  // Hooks
  const { data } = useMerchant()
  const [isOpen, setIsOpen] = useState(false)
  const {
    input: { value },
  } = useField('price')
  const {
    input: { value: paymentMethodValue },
  } = useField('paymentMethod')

  const [calculations, setCalculations] = useState<{
    [key: number]: AmortizationCalculation
  }>()

  const detailsModalRef = useRef<Modal>(null)

  const { t } = useTranslation()

  // Parse the price from the form value
  const price = parseInt(value, 10) || 0

  const [loanOptions, { priorityLoanOption, longestTermLoanOption }] =
    useFinanceOptions(data)

  // Fetch the finance calculations
  const previousPrice = useRef<number>()
  useEffect(() => {
    if (previousPrice.current !== price) {
      const loanOptionsToCalculate = loanOptions
        .filter(o => !o.disabled)
        .filter(Boolean)

      if (loanOptionsToCalculate.length === 0) return

      fetchLoanOptionCalculations(price, loanOptionsToCalculate).then(
        calculations => {
          setCalculations(calculations)
        }
      )
    }
    previousPrice.current = price
  }, [loanOptions, price])

  // Merchant data is required to get the finance options
  if (!data) return null

  // If there are no finance options, don't show the finance section
  if (loanOptions.length === 0) return null

  return (
    <AnimatePresence>
      <motion.div
        key="finance-options"
        style={{ opacity: 0, height: 0, scale: 0.98 }}
        animate={{
          opacity: 1,
          scale: 1,
          height: 'auto',
        }}
        exit={{
          opacity: 0,
          height: 0,
          scale: 0.98,
        }}
      >
        <div className="subheading text-tabeo-ink-1">{t('Finance')}</div>
        <AnimatePresence initial={false}>
          {loanOptions
            .sort((a, b) => a.numberOfMonths - b.numberOfMonths)
            .map(o => {
              const amortizationCalculation =
                calculations?.[o.numberOfMonths] || null

              const isSelected = getValueForLoanOption(o) === paymentMethodValue

              const isPriorityOrLongest =
                isSameLoanOption(o, priorityLoanOption) ||
                isSameLoanOption(o, longestTermLoanOption)

              const isAfterPriorty = priorityLoanOption
                ? o.numberOfMonths > priorityLoanOption.numberOfMonths
                : false

              return (
                (isOpen || isSelected || isPriorityOrLongest) && (
                  <motion.div
                    key={getValueForLoanOption(o)}
                    initial={isSelected ? false : { opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={
                      // fix exit animation for the longer options when updating the price
                      // most likely a bug in the library, it renders the exiting item right after the entering item
                      !isOpen && isAfterPriorty
                        ? undefined
                        : {
                            opacity: 0,
                            height: 0,
                          }
                    }
                  >
                    <Tooltip
                      disabled={!o.disabled}
                      content={p => (
                        <Tooltip.Title {...p.popper}>
                          <Tooltip.Arrow {...p.arrow} />
                          {o.numberOfMonths <= 12
                            ? t(
                                'Price must be between {{minPrice}} - {{maxPrice}} to enable Finance.',
                                {
                                  minPrice: format(FINANCE_MIN_PRICE),
                                  maxPrice: format(MAX_PAYMENT_AMOUNT),
                                }
                              )
                            : t(
                                'Price must be between {{minPrice}} - {{maxPrice}} to enable Finance options longer than 12 months.',
                                {
                                  minPrice: format(
                                    CUTOFF_PRICE_ABOVE_12_MONTHS
                                  ),
                                  maxPrice: format(MAX_PAYMENT_AMOUNT),
                                }
                              )}
                        </Tooltip.Title>
                      )}
                      placement="top"
                    >
                      <FinanceRadioCardOption
                        loanOption={o}
                        monthlyPaymentAmount={
                          o.disabled
                            ? undefined
                            : amortizationCalculation?.monthlyInvoicePaymentAmount
                        }
                        onViewDetails={() =>
                          detailsModalRef?.current?.open({
                            loanOption: o,
                            amortizationCalculation,
                          })
                        }
                        popular={is12Months(o)}
                        disabled={o.disabled}
                      />
                    </Tooltip>
                  </motion.div>
                )
              )
            })}
        </AnimatePresence>
        {loanOptions.length > 2 && (
          <button
            type="button"
            onClick={() => setIsOpen(v => !v)}
            className="anchor caption mt-4 inline-flex items-center gap-1"
          >
            {isOpen
              ? t('Show less Finance terms')
              : t('Show all Finance terms')}
            {isOpen ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </button>
        )}
        <FinanceDetailsModal ref={detailsModalRef} />
      </motion.div>
    </AnimatePresence>
  )
}

export function FinanceActions() {
  const { form, state, currentIndex, flowSteps } = useNewTransactionFlow()
  const { staffPerson } = state.payment || {}
  const applyWithClientModalRef = useRef<Modal>()
  const { t } = useTranslation()
  const { submitting } = form.current?.getState() || {}
  const isMobile = useMobile()

  function handleApplyWithClientSubmit(values: any) {
    // Set the staff person in the form
    const staffPerson = {
      name: values.staffPerson,
    }
    form.current?.registerField(
      'staffPerson',
      () => {},
      {},
      {
        initialValue: staffPerson,
      }
    )

    // Close the modal and submit the form
    applyWithClientModalRef.current?.close()
    form.current?.submit()
  }

  function handleApplyWithClientButtonClick() {
    // Trigger form validation to disaply errors if form is not valid
    const { valid } = form.current?.getState() || {}
    if (!valid) {
      form.current?.submit()
    } else {
      // Open the apply with client modal
      applyWithClientModalRef.current?.open()
    }
  }

  const { isLast } = flowSteps[currentIndex]
  if (!isLast) {
    return (
      <Button
        variant="primary"
        className="w-full"
        type="submit"
        onClick={form.current?.submit}
      >
        {t('Continue')}
      </Button>
    )
  }

  return (
    <>
      <Portal>
        <ApplyWithClientModal
          innerRef={applyWithClientModalRef}
          onSubmit={handleApplyWithClientSubmit}
        />
      </Portal>
      {[
        <Button
          variant="secondary"
          className="w-full"
          onClick={handleApplyWithClientButtonClick}
          loading={!!staffPerson && submitting}
          disabled={submitting}
          trailingIcon={p => <ArrowTopRightOnSquareIcon {...p} />}
          key="apply-with-client"
        >
          {t('Apply with patient')}
        </Button>,
        <Button
          variant="primary"
          className="w-full"
          type="submit"
          onClick={form.current?.submit}
          loading={!staffPerson && submitting}
          disabled={submitting}
          key="send-payment-request"
        >
          {t('Send Finance Link')}
        </Button>,
      ].sort(() => {
        if (isMobile) {
          return 1
        }
        return -1
      })}
    </>
  )
}
