import { plansorchestrator } from '@tabeo/platform-api-client'
import Resource, { createResourceHook } from '@tabeo/resync'
import api from 'utils/platform-api'
import { useVendor } from './Vendor'

interface Params {
  vendorId: string
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
}

interface Data {
  items: plansorchestrator.ModelsSelectSubscriptionAggregate[] | null
  totalCount: number
}

class SubscriptionsV2 extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const { searchQuery, limit, offset, conditions } = this.params

    const response =
      await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSelectPost(
        {
          vendorId: this.params.vendorId,
          limit,
          offset,
          body: {
            searchField: searchQuery,
            planIds: conditions?.planId ? [conditions.planId] : undefined,
            statuses: conditions?.status,
          },
        }
      )

    const data = {
      totalCount: response.paginationSummary?.total || 0,
      items: response.subscriptions || null,
    }

    this.data = data

    return data
  }

  create = async (payload: {
    customer: plansorchestrator.ModelsCreateInvitePlansSubscriptionRequest
    planId: string
  }) => {
    const response =
      await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansPlansPlanIdInvitePost(
        {
          vendorId: String(this.params.vendorId),
          planId: payload.planId,
          body: {
            ...payload.customer,
          },
        }
      )

    return response
  }
}

export default SubscriptionsV2
export const useResource = createResourceHook(SubscriptionsV2)
export function useSubscriptionsV2(p?: Params | (() => Params)) {
  const { data: vendor } = useVendor()

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const vendorIdParam = params?.vendorId || vendor?.id

  return useResource(() => {
    if (!vendorIdParam) {
      throw new Error('Vendor ID is null')
    }

    return {
      ...params,
      vendorId: vendorIdParam,
    }
  })
}
