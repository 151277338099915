'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('./tslib.es6-1ef680b2.js');
require('i18next');
require('./i18n/index.js');
var config = require('./config.js');
var Raven = _interopDefault(require('raven-js'));

function ravenSetup(dsn) {
    if (dsn && window.location.hostname !== 'localhost') {
        Raven.config(dsn, {
            environment: config.getConfig().ENV,
            maxBreadcrumbs: 25,
        });
        Raven.install();
        window.addEventListener('unhandledrejection', function (event) {
            // Prevent error output on the console:
            event.preventDefault();
            Raven.captureException(event.reason);
        });
    }
}

exports.default = ravenSetup;
