import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Basket, PaymentRefund } from '@tabeo/ts-types'

const subResources = formatSubresources(['basket.patient'])

export type PaymentRefundWithBasket = PaymentRefund & { basket: Basket }
interface Data {
  items: null | PaymentRefundWithBasket[]
  totalCount: number
}

interface Params {
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
  orderBy?: {
    field: string
    direction: 'asc' | 'desc'
  }
}

class Refunds extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const {
      searchQuery,
      limit = 10,
      offset = 0,
      conditions = [],
      orderBy: { field = 'updatedAt', direction = 'desc' } = {
        field: 'updatedAt',
        direction: 'desc',
      },
    } = this.params

    const pagination = `&limit=${limit}&offset=${offset}`
    const jsonConditions = `&jsonConditions=${JSON.stringify(conditions)}`
    const order = `&order=${field}:${direction}`

    const endpoint = searchQuery
      ? `/merchant/refund-search?searchPhrase=${encodeURIComponent(
          searchQuery
        )}&${subResources}`
      : `/merchant/refunds?${subResources}`

    const {
      data: { refunds },
      totalCount,
    } = await api.get(`${endpoint}${jsonConditions}${pagination}${order}`, {
      responseDataPath: 'data',
    })

    this.data = {
      totalCount,
      items: refunds,
    }

    return this.data
  }
}

export default Refunds
export const useRefunds = createResourceHook(Refunds)
