import React, { createContext, useContext, useMemo } from 'react'
import Item from './Item'
import ScarfProps from '../../../types/ScarfProps'

export interface ItemType {
  label: string
  value: any
  disabled?: boolean
  props?: ScarfProps
}

export type RenderProps = {
  item: ItemType & { index: number }
  onMouseDown: (e: React.MouseEvent) => void
  onKeyDown: (e: React.KeyboardEvent) => void
  onBlur: (e: React.FocusEvent) => void
  onFocus: (e: React.FocusEvent) => void
  selected: boolean
  focus: boolean
  key: string
  disabled: boolean
  loading: boolean
  horizontal?: boolean
  name: string
}

export const RadioItemInput = () => {
  const { renderProps } = useRadioContext()
  return (
    <input
      type="radio"
      name={renderProps.name}
      id={renderProps.key}
      style={{
        opacity: 0,
        position: 'absolute',
      }}
      onMouseDown={renderProps.onMouseDown}
      onKeyDown={renderProps.onKeyDown}
      onFocus={renderProps.onFocus}
      onBlur={renderProps.onBlur}
      value={renderProps.item.value}
    />
  )
}

export const RadioItemLabel = (props: ScarfProps) => {
  const { renderProps } = useRadioContext()
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={renderProps.key}>
      <Item {...(renderProps as any)} {...props} />
    </label>
  )
}

const RadioContext = createContext<{ renderProps: RenderProps }>({
  renderProps: {
    item: { index: 0, label: '', value: '' },
    onMouseDown: () => {},
    onKeyDown: () => {},
    onBlur: () => {},
    onFocus: () => {},
    selected: false,
    focus: false,
    key: '',
    disabled: false,
    loading: false,
    name: '',
  },
})

const useRadioContext = () => useContext(RadioContext)

export type RadioItemWrapperProps = {
  children: React.ReactNode
  renderProps: RenderProps
}

export const RadioItemWrapper = ({
  children,
  renderProps,
}: RadioItemWrapperProps) => {
  const value = useMemo(() => ({ renderProps }), [renderProps])
  return <RadioContext.Provider value={value}>{children}</RadioContext.Provider>
}
