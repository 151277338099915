import { plansorchestrator } from '@tabeo/platform-api-client'
import { Accordion, Panel } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'

type DocumentsProps = {
  data?: plansorchestrator.ModelsGetSubscriptionAggregate
}

function Documents({ data }: DocumentsProps) {
  const { t } = useTranslation()

  if (!data?.documents) {
    return null
  }

  return (
    <Panel title={t('Documents')} data-testid="documents-panel">
      <Accordion title={t('Membership contract')} subtitle={data?.plan?.name}>
        <Button
          size="sm"
          as="a"
          href={data?.documents?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('View document')}
        </Button>
      </Accordion>
    </Panel>
  )
}

export default Documents
