import { Attributes, Button, Modal, Panel, View } from '@tabeo/scarf'
import { toast } from '@tabeo/scarf2'
import { Basket } from '@tabeo/ts-types'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useBasket } from 'resources/Basket'
import { useMerchant } from 'resources/Merchant'
import UpsertModal from './UpsertModal'

export type ClinicianPanelProps = {
  basket: Basket
} & React.ComponentProps<typeof View>

export default function ClinicianPanel({
  basket: pBasket,
  ...rest
}: ClinicianPanelProps) {
  const { t } = useTranslation()
  const { data: basket, resource } = useBasket({ id: pBasket.id })
  const modal = useRef<Modal>()
  const { data: merchant } = useMerchant()

  const { clinicianName } = basket || pBasket

  return (
    <>
      <UpsertModal
        ref={modal}
        data={basket}
        onSubmit={async formData => {
          await resource?.update({
            ...basket,
            ...formData,
          })
          if (!clinicianName) {
            toast.success(t('Clinician details added'))
          } else {
            toast.success(t('Clinician details updated'))
          }
        }}
        isCliniciansAndTreatmentsEnabled={
          merchant?.flags?.is_clinicians_treatments_enabled
        }
      />
      <Panel
        header={
          <>
            <Panel.Title>{t('Clinician')}</Panel.Title>
            <Button
              sizeVariant="sm"
              variant="secondary"
              onClick={() => modal.current?.open()}
            >
              {clinicianName ? t('Edit') : t('Add')}
            </Button>
          </>
        }
        data-testid="clinician-panel"
        {...rest}
      >
        {clinicianName ? (
          <Attributes
            p={5}
            sections={[
              [
                {
                  label: t('Name'),
                  value: clinicianName,
                },
              ],
            ]}
          />
        ) : (
          <div className="caption p-5 text-tabeo-ink-2">
            {t('Clinician details not provided.')}
          </div>
        )}
      </Panel>
    </>
  )
}
