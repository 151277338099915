import {
  AddressSelect,
  Button,
  DateInput,
  Flex,
  Icons,
  Modal,
  PhoneInput,
  Prompt,
  Radio,
  Text,
  TextInput,
  Title,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email as emailValidator,
  name,
  phone,
} from '@tabeo/sharpei/utils/validations'
import { Patient } from '@tabeo/ts-types'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

export type UpsertModalProps = {
  onSubmit: (p: Patient) => void
  data?: Patient
} & React.ComponentProps<typeof Modal>

const optional = (fn: any) => (value: any) => value ? fn(value) : undefined

const UpsertModal = React.forwardRef(
  ({ onSubmit, data, ...rest }: UpsertModalProps, ref: any) => {
    const { t } = useTranslation()

    // @ts-ignore
    const { gender, firstName, lastName, email, phoneNumber, address } =
      data || {}

    const hasAnyData =
      gender || firstName || lastName || email || phoneNumber || address

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          async function handleSubmit({
            homeAddressJSON,
            ...values
          }: Patient & { homeAddressJSON: string }) {
            try {
              const payload = {
                ...values,
                homeAddressJSON: homeAddressJSON
                  ? JSON.parse(homeAddressJSON)
                  : homeAddressJSON,
              }

              await onSubmit(payload)
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <Title mb={5}>
                {hasAnyData
                  ? t('Edit patient details')
                  : t('Add patient details')}
              </Title>
              <Form
                onSubmit={handleSubmit}
                initialValues={{
                  ...data,
                  homeAddressJSON: address?.addressDetails
                    ? JSON.stringify(address?.addressDetails)
                    : undefined,
                }}
                keepDirtyOnReinitialize
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="gender"
                      label={
                        <Prompt mb={1} fontWeight="semibold">
                          <Trans>
                            Title{' '}
                            <Prompt display="inline" color="ink.2">
                              (optional)
                            </Prompt>
                          </Trans>
                        </Prompt>
                      }
                      component={Radio}
                      width={['100%', '50%']}
                      horizontal
                      items={[
                        { label: t('Mr'), value: 'm' },
                        { label: t('Ms'), value: 'f' },
                      ]}
                      containerProps={{
                        mr: [0, 5],
                      }}
                      renderItem={(p: any) => <TabbedRadioItem {...p} />}
                    />

                    <div className="flex flex-col desktop:flex-row desktop:space-x-5">
                      <Field
                        name="firstName"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              First name{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        placeholder={t('E.g. John')}
                        component={TextInput}
                        validate={optional(name)}
                        maxWidth="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        variant="outline"
                      />
                      <Field
                        name="lastName"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              Last name{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        placeholder={t('E.g. Smith')}
                        component={TextInput}
                        validate={optional(name)}
                        maxWidth="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        variant="outline"
                      />
                    </div>

                    <div className="flex flex-col desktop:flex-row desktop:space-x-5">
                      <Field
                        name="email"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              Email address{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        placeholder={t('E.g. john@email.co.uk')}
                        component={TextInput}
                        validate={optional(emailValidator)}
                        maxWidth="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        variant="outline"
                      />
                      <Field
                        name="phoneNumber"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              Mobile number{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        component={PhoneInput}
                        validate={optional(phone)}
                        maxWidth="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        variant="outline"
                      />
                    </div>

                    <div className="flex flex-col desktop:flex-row desktop:space-x-5">
                      <Field
                        name="birthdate"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              Date of birth{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        component={DateInput}
                        maxWidth="100%"
                        width="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        // @ts-ignore
                        variant="outline"
                      />
                      <Field
                        name="externalID"
                        label={
                          <Prompt mb={1} fontWeight="semibold">
                            <Trans>
                              PMS patient ID{' '}
                              <Prompt display="inline" color="ink.2">
                                (optional)
                              </Prompt>
                            </Trans>
                          </Prompt>
                        }
                        component={TextInput}
                        maxWidth="100%"
                        containerProps={{
                          flex: '1',
                        }}
                        variant="outline"
                      />
                    </div>

                    <Field
                      name="homeAddressJSON"
                      label={
                        <Prompt mb={1} fontWeight="semibold">
                          <Trans>
                            Home address{' '}
                            <Prompt display="inline" color="ink.2">
                              (optional)
                            </Prompt>
                          </Trans>
                        </Prompt>
                      }
                      component={AddressSelect}
                      clearable
                      clearRenderer={() => (
                        <Icons.Clear
                          mr={2}
                          height="20px"
                          width="20px"
                          fill="ink.3"
                        />
                      )}
                      placeholder={
                        <Flex justifyContent="flex-start">
                          <Icons.Search fill="#919EAB" mr={2} ml="-2px" />
                          <Text color="ink.2">
                            {t('Enter address or postcode')}
                          </Text>
                        </Flex>
                      }
                      variant="outline"
                      maxWidth="100%"
                    />

                    <div className="mt-10">
                      <Button loading={submitting} mb={4} width="100%">
                        {hasAnyData
                          ? t('Confirm changes')
                          : t('Confirm patient details')}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={close}
                        width="100%"
                      >
                        {t('Close')}
                      </Button>
                    </div>

                    <FormError />
                  </form>
                )}
              </Form>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default UpsertModal
