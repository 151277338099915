import { PlatformApi } from '@tabeo/platform-api-client'
import config from 'config'
import { authMiddleware } from 'resources/middlewares'

const api = new PlatformApi({
  basePath: config.PLATFORM_API_URL,
  middleware: [authMiddleware],
})

export default api
