import { plansorchestrator } from '@tabeo/platform-api-client'
import Active from './Active'
import Sent from './Sent'
import SignUp from './SignUp'

type ActionPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

function ActionPanel({ data }: ActionPanelProps) {
  if (data?.status === 'sent') {
    return <Sent />
  }

  if (data?.status === 'sign_up') {
    return <SignUp />
  }

  if (data?.status === 'active') {
    return <Active />
  }

  return null
}

export default ActionPanel
