/* eslint-disable react/prop-types */
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import useFormInput from '../../../hooks/useFormInput'
import { useDesktop } from '../../../hooks/useMatchMedia'
import ScarfProps from '../../../types/ScarfProps'
import { Merge } from '../../../types/helpers'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import { ReactComponent as NotFoundSVG } from '../../DataTable/assets/not-found.svg'
import Flex from '../../Flex'
import Input from '../../Form/Input'
import * as Icons from '../../Icons'
import Text from '../../Text'
import View from '../../View'
import Container from './Container'
import Hoverable from './Hoverable'

export interface Item {
  value: string | number
  label?: string
  [key: string]: any
}

export type SelectProps = Merge<
  ScarfProps,
  {
    loadOptions?: (query: string) => Promise<Item[]> | Item[] | undefined
    options?: Item[]
    filtering?: boolean
    value?: Item | Item[]
    renderItem?: (item: Item, props: any) => React.ReactElement
    onChange: (value: Item | Item[] | null | undefined, mode?: string) => void
    onClose?: () => void
    onHitsUpdate?: (() => void) | null
    placeholder?: string
    selectedFirst?: boolean
    disabledValues?: (string | number)[]
    mode?: string
    modeOptions?: { value: string; label: string }[]
  }
>

const Select = forwardRef<any, SelectProps>(
  (
    {
      loadOptions,
      options,
      filtering,
      value,
      renderItem,
      onChange,
      onClose,
      onHitsUpdate,
      placeholder,
      selectedFirst,
      disabledValues = [],
      onBlur = () => {},
      onFocus = () => {},
      mode: controlledMode,
      modeOptions,
      ...rest
    },
    ref
  ) => {
    const desktop = useDesktop()
    const input = useFormInput('')
    const [selected, setSelected] = useState<Item | Item[] | undefined | null>(
      value
    )
    const [items, setItems] = useState<Item[]>(options || [])
    const [mode, setMode] = useState(controlledMode)

    const [loading, setLoading] = useState<boolean>()

    const handleClick = (option: Item | null, newMode = mode) => {
      let newSelected

      if (option && Array.isArray(selected)) {
        const index = selected.findIndex((item) => item.value === option.value)

        if (index === -1) {
          newSelected = [...selected, option]
        } else {
          newSelected = [
            ...selected.slice(0, index),
            ...selected.slice(index + 1),
          ]
        }
      } else if (option) {
        newSelected = (selected as Item)?.value === option.value ? null : option
      } else {
        newSelected = selected
      }

      setSelected(newSelected)
      if (desktop) {
        handleChange(newSelected, newMode)
      }
    }

    const isSelected = (option: Item) => {
      if (Array.isArray(selected)) {
        return selected.findIndex((item) => item.value === option.value) !== -1
      }

      return selected?.value === option.value
    }

    const handleChange = useCallback(
      (selected: any, mode: any) => {
        onFocus()
        onChange(selected, mode)
        onBlur()
      },
      [onFocus, onChange, onBlur]
    )

    const previousValue = React.useRef(value)
    useEffect(() => {
      const loader = async (query: string) => {
        if (loadOptions) {
          setLoading(true)
          const newOptions = await loadOptions(query)
          setLoading(false)
          setItems(newOptions || [])
        } else {
          setItems(options || [])
        }
      }

      if (previousValue.current !== input.value) {
        loader(input.value)
      }
      previousValue.current = input.value
    }, [input.value, loadOptions, options])

    useEffect(() => {
      // Notify parent to update position
      onHitsUpdate && onHitsUpdate()
    }, [items.length, onHitsUpdate])

    useEffect(() => {
      // Sync value and selected, make it "controlled"
      setSelected(value)
    }, [value])

    useEffect(() => {
      // Sync mode, make it "controlled"
      setMode(controlledMode)
    }, [controlledMode])

    const render = (item: Item) =>
      renderItem ? (
        <Hoverable key={item.value}>
          {(hover) =>
            renderItem(item, {
              hover,
              selected: isSelected(item),
              // @ts-ignore
              disabled: disabledValues.indexOf(item.value) !== -1,
              onClick: () => {
                setTimeout(() => handleClick(item), 0)
              },
              cursor: 'pointer',
              _notLast: {
                borderBottom: 1,
              },
              _last: {
                borderBottom: [1, 0],
              },
            })
          }
        </Hoverable>
      ) : null

    return (
      <Container ref={ref} maxHeight={['100%', '280px']} {...rest}>
        {!!modeOptions?.length && (
          <Flex p={3} borderBottom={1}>
            {modeOptions.map((mo) => (
              <View
                flex="1"
                key={mo.value}
                onClick={() => {
                  setMode(mo.value)
                  handleClick(null, mo.value)
                }}
                bg={mo.value === mode ? 'primary.3' : 'sky.2'}
                color={mo.value === mode ? 'white' : 'ink.2'}
                borderColor={mo.value === mode ? 'primary.3' : 'sky.1'}
                borderTopWidth={1}
                borderBottomWidth={1}
                borderStyle="solid"
                textAlign="center"
                p={2}
                _first={{
                  borderLeftWidth: 1,
                  borderTopLeftRadius: 1,
                  borderBottomLeftRadius: 1,
                }}
                _last={{
                  borderRightWidth: 1,
                  borderTopRightRadius: 1,
                  borderBottomRightRadius: 1,
                }}
                cursor="pointer"
              >
                <Text color="currentColor" fontSize={0} fontWeight="semibold">
                  {mo.label}
                </Text>
              </View>
            ))}
          </Flex>
        )}
        {filtering && (
          <View p={3} borderBottom={1}>
            <Input
              {...input}
              ref={(node: any) => {
                if (node?.input) {
                  node.input.focus()
                }
              }}
              variant="outline"
              left={<Icons.Search fill="ink.2" />}
              placeholder={placeholder}
              maxWidth="100%"
            />
          </View>
        )}
        <View flex="1" overflowY="auto">
          {loading && (
            <Flex height="100px" justifyContent="center">
              <Icons.Spinner spinning />
            </Flex>
          )}
          {!loading && items.length === 0 && (
            <View>
              <Flex justifyContent="center">
                <View
                  as={NotFoundSVG}
                  color="primary.3"
                  transform="scale(0.7)"
                />
              </Flex>
              <Text textAlign="center" pb={5} px={5}>
                No results found
              </Text>
            </View>
          )}
          {selectedFirst &&
            !loading &&
            !input.value &&
            [
              ...(Array.isArray(selected)
                ? selected
                : selected
                ? [selected]
                : []),
            ].map(render)}
          {!loading &&
            items
              .sort((a, b) =>
                selectedFirst
                  ? isSelected(a)
                    ? isSelected(b)
                      ? 0
                      : -1
                    : 1
                  : 0
              )
              .filter((i) =>
                selectedFirst ? (!input.value ? !isSelected(i) : true) : true
              )
              .map(render)}
        </View>
        <View display={['block', 'none']} p={[5]}>
          <ButtonGroup>
            <Button
              onClick={() => {
                handleChange(selected, mode)
                onClose?.()
              }}
            >
              Save
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </ButtonGroup>
        </View>
      </Container>
    )
  }
)

export default Select
