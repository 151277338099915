import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Document } from '@tabeo/ts-types'
import { NormalizedMerchant } from 'types/NormalizedMerchant'
import { initFullstory } from 'utils/fullstory'
import { initHeapAnalytics } from 'utils/heapAnalytics'
import normalize from './normalizers/merchant'

class Merchant extends Resource<NormalizedMerchant> {
  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const subResources = formatSubresources([
      'industries',
      'officers',
      'bankDetails',
      'checks',
      'metadata',
      'flags',
      'uploads',
      'products',
      'merchantRiskProfiles',
      'merchantFundConfigs',
      'merchantTreatments.treatment',
      'merchantClinicians.clinician',
      'merchantClinicians.clinician.boards.board',
      'merchantClinicians.clinician.specializations',
      'tradingAddressDetails',
      'activeFeeGroup',
    ])

    const [{ merchant }, { documents }] = await Promise.all([
      api.get(`/merchant?${subResources}`),
      api.get('/merchant/documents?limit=1000'),
    ])

    // Store merchant slug in local storage
    window.localStorage.setItem('merchantSlug', merchant?.slug)
    // Make sure to trigger storage event
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: 'merchantSlug',
        newValue: merchant?.slug,
      })
    )

    // Conditionally enable fullstory
    const isTPEnabled = merchant.flags.is_terminal_payment_capable
    const isLPEnabled = merchant.flags.is_link_payment_capable
    const searchParams = new URLSearchParams(window.location.search)

    const fullstoryRecordingForAllUsersFrom =
      searchParams.get('fullstoryRecordingForAllUsersFrom') || '2023-05-15'
    const fullStoryRecordingForAllUsersFromDate = new Date(
      fullstoryRecordingForAllUsersFrom
    )
    const fullstoryRecordingForAllUsersTill =
      searchParams.get('fullstoryRecordingForAllUsersTill') || '2023-06-15'
    const FullStoryRecordingForAllUsersTillDate = new Date(
      fullstoryRecordingForAllUsersTill
    )
    const shouldRecordFullStoryForAllUsers =
      new Date() >= fullStoryRecordingForAllUsersFromDate &&
      new Date() <= FullStoryRecordingForAllUsersTillDate

    const isFullStoryEnabled =
      isTPEnabled || isLPEnabled || shouldRecordFullStoryForAllUsers

    if (!this.data) {
      const {
        merchantUser: {
          id,
          user: { email },
          firstName,
          lastName,
          gender,
          postcode,
          jobFunction,
        },
      } = await api.get(`/merchant-user?subResource=user`)

      const uid = `merchant-user-${id}`
      const properties = {
        displayName: `${firstName} ${lastName} - ${merchant.tradingName}`,
        email,
        gender,
        userPostcode: postcode,
        jobFunction,
        merchantUserId: id,
        merchantId: merchant.id,
        merchantTradingName: merchant.tradingName,
        merchantCompanyType: merchant.companyType,
        merchantCountryCode: merchant.countryCode,
        merchantGroupName: merchant.groupName,
        isMerchantSuspened: merchant.isSuspended,
        merchantLoanConfirmationMode: merchant.loanConfirmationMode,
        merchantPaymentGateway: merchant.paymentGateway,
        merchantPostcode: merchant.postcode,
        merchantSector: merchant.sector,
        merchantStatus: merchant.status,
        merchantSimplifiedStatus: merchant.simplifiedStatus,
        merchantStripeFundGatewayState: merchant.stripeFundGatewayState,
        merchantTradingAddressPostcode:
          merchant.tradingAddressDetails?.PostalCode,
        ...merchant.flags,
      }

      if (isFullStoryEnabled) {
        initFullstory()
        // @ts-ignore
        window.FS?.anonymize()
        // @ts-ignore
        window.FS?.identify?.(uid, properties)
      }

      initHeapAnalytics()
      // @ts-ignore
      window.heap?.identify(uid)
      // @ts-ignore
      window.heap?.addUserProperties(properties)
    }

    this.data = normalize({
      ...merchant,
      documents,
      newTermsAndConditions: documents.filter(
        (d: Document) =>
          d.type === 'merchant_terms_and_conditions' && d.status === 'new'
      ),
    })
  }

  static create = (payload: any) => api.post('/merchants', payload)

  update = async (payload: any) => {
    await api.put('/merchant', payload)
    await this.fetch()
  }

  addCompanyDetails = async (payload: any) => {
    await api.post(`/merchant/details`, payload)
    await this.fetch()
  }

  createDirectorDetails = async (payload: any) => {
    await api.post(`/merchant/officers`, payload)
    await this.fetch()
  }

  updateDirectorDetails = async (payload: any) => {
    await api.put(`/merchant/officers`, payload)
    await this.fetch()
  }

  addBankDetails = async (payload: any) => {
    await api.post(`/merchant/bank-details`, payload)
    await this.fetch()
  }

  updateBankDetailsStatus = async () => {
    await api.post(`/merchant/bank-details/verification-token-disclosure`, {})
    await this.fetch()
  }

  updateMerchantMetadata = async (payload: any) => {
    await api.put(`/merchant/metadata`, payload)
    await this.fetch()
  }

  setMerchantFlag = async (payload: any) => {
    await api.put(`/merchant/flags`, payload)
    await this.fetch()
  }

  requestLeadsAccess = async () => {
    await api.post(`/merchant/lead-management-request`, {})
    await this.fetch()
  }

  uploadMerchantFile = async (payload: any) => {
    await api.post(`/merchant/uploads`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    await this.fetch()
    return this.data
  }

  acceptTerms = async (id: number) => {
    await api.put(`/merchant/documents/${id}`, {})
    await this.fetch()
  }

  getStripeConnectOnboardingURL = async () => {
    const {
      projections: {
        stripeOnboardingLink: { link },
      },
    } = await api.post(`/merchant/stripe-onboarding-link`, {})
    return link
  }

  setCustomField = async (key: string, value: any) => {
    const { metadata: { customFields = {} } = {} } = this.data || {}
    const payload = {
      type: 'custom_fields',
      customFields: {
        ...customFields,
        [key]: value,
      },
    }
    const response = await api.put(`/merchant/metadata`, payload)
    await this.fetch()
    return response
  }

  addTreatment = async ({ treatmentId, ...payload }: any) => {
    /**
     * It doens't look right, but backend made this decision...
     * Should be POST /merchant/merchant-treatments
     */
    const response = await api.post(
      `/merchant/treatment/${treatmentId}`,
      payload
    )
    await this.fetch()
    return response
  }

  updateTreatment = async (id: number, payload: any) => {
    const response = await api.put(`/merchant/treatments/${id}`, payload)
    await this.fetch()
    return response
  }

  removeTreatment = async (id: number) => {
    const response = await api.delete(`/merchant/treatments/${id}`)
    await this.fetch()
    return response
  }

  addClinician = async ({ clinicianId, ...payload }: any) => {
    /**
     * It doens't look right, but backend made this decision...
     * Should be POST /merchant/merchant-clinicians
     */
    const response = await api.post(
      `/merchant/clinician/${clinicianId}`,
      payload
    )

    await this.fetch()
    return response
  }

  updateClinician = async (id: number, payload: any) => {
    const response = await api.put(`/merchant/clinicians/${id}`, payload)

    await this.fetch()
    return response
  }

  removeClinician = async (id: number) => {
    const response = await api.delete(`/merchant/clinicians/${id}`)
    await this.fetch()
    return response
  }

  toggleClinicianStatus = async ({ id, status }: any) => {
    const response = await api.put(
      status === 'deactivated'
        ? `/merchant/clinicians/${id}/activate`
        : `/merchant/clinicians/${id}/deactivate`,
      {}
    )
    await this.fetch()
    return response
  }
}

export default Merchant
export const useMerchant = createResourceHook(Merchant)
