import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { Currency } from '@tabeo/ts-types'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import normalize from './normalizers/terminalPayment'

const subResources = formatSubresources([
  'basket.patient',
  'basket.merchantTreatment.treatment',
  'basket.treatmentName',
])

interface Data {
  items: null | NormalizedTerminalPayment[]
  totalCount: number
}

interface Params {
  searchQuery?: string
  limit?: number
  offset?: number
  conditions?: any
  orderBy?: {
    field: string
    direction: 'asc' | 'desc'
  }
}

class TerminalPayments extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const {
      searchQuery,
      limit = 10,
      offset = 0,
      conditions = [],
      orderBy: { field = 'createdAt', direction = 'desc' } = {
        field: 'createdAt',
        direction: 'desc',
      },
    } = this.params

    const pagination = `&limit=${limit}&offset=${offset}`
    const jsonConditions = `&jsonConditions=${JSON.stringify(conditions)}`
    const order = `&order=${field}:${direction}`

    const endpoint = searchQuery
      ? `/merchant/terminal-payment-search?searchPhrase=${encodeURIComponent(
          searchQuery
        )}&${subResources}`
      : `/merchant/terminal-payments?${subResources}`

    const {
      data: { terminalPayments },
      totalCount,
    } = await api.get(`${endpoint}${jsonConditions}${pagination}${order}`, {
      responseDataPath: 'data',
    })

    this.data = {
      totalCount,
      items: terminalPayments.map((tp: any) => normalize(tp)),
    }

    return this.data
  }

  static create = async ({
    ...payload
  }: {
    merchantTreatmentID?: number
    merchantClinicianID?: number
    patientID?: number
    patient?: { firstName?: string; lastName?: string; gender?: string }
    amount: number
    currency: Currency
    treatmentName?: string
    clinicianName?: string
  }) => {
    const {
      projections: { terminalPayment },
    } = await api.post('/merchant/terminal-payments', payload)

    return terminalPayment
  }
}

export default TerminalPayments
export const useTerminalPayments = createResourceHook(TerminalPayments)
