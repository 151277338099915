import {
  ArrowTopRightOnSquareIcon,
  PrinterIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import {
  BanknotesIcon,
  HeartIcon,
  InformationCircleIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  Button,
  FFRadioCards,
  FFRadioTabs,
  FFTextInput,
  RadioCards,
  RadioTabs,
  Stepper,
  Tag,
  TextInput,
  Tile,
  Toaster,
  Tooltip,
  toast,
} from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { email, name, required } from '@tabeo/sharpei/utils/validations'
import { Form } from 'react-final-form'
import SummaryCart from './SummaryCart'
import { Field } from './form/Field'
import useBreakpoints from './hooks/useBreakpoints'
import { ShieldPlusIcon } from './icons/24'

function NntsComponents() {
  const breakpoints = useBreakpoints()

  return (
    <div className="px-4 py-10">
      <h1 className="title mb-2">NNTS Components</h1>
      <div className="space-y-4">
        <div>
          <h2 className="subheading mb-1">Tile</h2>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:gap-y-5 xl:grid-cols-3">
            {[
              'Bridge',
              'Check-up',
              'FastTrack SmileDirectClub Nighttime Clear Aligners',
            ].map((t, i) => (
              <Tile key={t} checked={i === 1}>
                <Tile.Label>{t}</Tile.Label>
                <Tile.Icon icon={ArrowRightIcon} />
              </Tile>
            ))}

            <Tile>
              <Tile.Label>Other</Tile.Label>
              <Tile.Icon icon={PlusIcon} />
            </Tile>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Stepper</h2>
          <div className="max-w-xs space-y-3 md:space-y-4">
            <Stepper editable>
              <Stepper.Header>
                <Stepper.Icon icon={ShieldPlusIcon} />
                <Stepper.Label>Clinician</Stepper.Label>
              </Stepper.Header>
              <Stepper.Body>Dr. John Smith</Stepper.Body>
            </Stepper>
            <Stepper active>
              <Stepper.Header>
                <Stepper.Icon icon={HeartIcon} />
                <Stepper.Label>Treatment</Stepper.Label>
              </Stepper.Header>
              <Stepper.Body>Check-up</Stepper.Body>
            </Stepper>
            <Stepper>
              <Stepper.Header>
                <Stepper.Icon icon={UserPlusIcon} />
                <Stepper.Label>Patient</Stepper.Label>
              </Stepper.Header>
              <Stepper.Body>John Smith</Stepper.Body>
            </Stepper>
            <Stepper empty>
              <Stepper.Header>
                <Stepper.Icon icon={BanknotesIcon} />
                <Stepper.Label>Payment</Stepper.Label>
              </Stepper.Header>
              <Stepper.Body />
            </Stepper>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">TextInput</h2>
          <TextInput />
        </div>
        <div>
          <h2 className="subheading mb-1">Field</h2>
          <Form onSubmit={() => {}}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field<string>
                  component={FFTextInput}
                  name="patient"
                  label="Patient"
                  description="Enter patient name here"
                  validate={[required, name]}
                />
              </form>
            )}
          </Form>
        </div>
        <div>
          <h2 className="subheading mb-1">Button</h2>
          <div className="space-y-4">
            <div className="space-x-4">
              <Button>Default</Button>
              <Button variant="primary">Primary</Button>
              <Button variant="ghost">Ghost</Button>
            </div>
            <div className="space-x-4">
              <Button size="sm">Small</Button>
              <Button size="md">Medium</Button>
              <Button size="lg">Large</Button>
            </div>
            <div className="space-x-4">
              <Button disabled>Disabled</Button>
              <Button loading>Loading</Button>
            </div>
            <div className="flex space-x-4">
              <Button leadingIcon={p => <PrinterIcon {...p} />}>Print</Button>
              <Button
                trailingIcon={p => <ArrowTopRightOnSquareIcon {...p} />}
                variant="primary"
              >
                Apply with client
              </Button>
              <Button leadingIcon={p => <TrashIcon {...p} />} />
            </div>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Summary Cart</h2>
          <div className="h-[540px] max-w-xs space-y-4">
            <SummaryCart>
              <SummaryCart.Body>
                <Stepper editable>
                  <Stepper.Header>
                    <Stepper.Icon icon={HeartIcon} />
                    <Stepper.Label>Treatment</Stepper.Label>
                  </Stepper.Header>
                  <Stepper.Body>Check-up</Stepper.Body>
                </Stepper>
                <Stepper editable>
                  <Stepper.Header>
                    <Stepper.Icon icon={UserPlusIcon} />
                    <Stepper.Label>Patient</Stepper.Label>
                  </Stepper.Header>
                  <Stepper.Body>John Smith</Stepper.Body>
                </Stepper>
                <Stepper active>
                  <Stepper.Header>
                    <Stepper.Icon icon={BanknotesIcon} />
                    <Stepper.Label>Payment</Stepper.Label>
                  </Stepper.Header>
                  <Stepper.Body>
                    £1,000.00
                    <div className="mt-2 space-y-1.5 bg-tabeo-sky-2 px-3 py-2 font-normal text-tabeo-ink-1 md:hidden">
                      <div className="caption">john.smith@example.com</div>
                      <div className="caption">+44 (0) 1234 567890</div>
                    </div>
                  </Stepper.Body>
                </Stepper>
              </SummaryCart.Body>
              <SummaryCart.Footer>
                <Button className="w-full" variant="primary">
                  Send Finance link
                </Button>
                <Button className="w-full" variant="secondary">
                  Apply with consumer
                </Button>
              </SummaryCart.Footer>
            </SummaryCart>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Tag</h2>
          <div className="space-y-4">
            <div>
              <div className="mb-1 text-sm">Medium</div>
              <div className="space-x-4">
                <Tag color="red">Red</Tag>
                <Tag color="green">Green</Tag>
                <Tag>Default</Tag>
                <Tag color="yellow">Yellow</Tag>
                <Tag color="blue">Blue</Tag>
                <Tag color="sea">Sea</Tag>
                <Tag color="teal">Teal</Tag>
                <Tag color="orange">Orange</Tag>
                <Tag color="ink">Ink</Tag>
              </div>
            </div>
            <div>
              <div className="mb-1 text-sm">Small</div>
              <div className="space-x-4">
                <Tag size="sm" color="red">
                  Red
                </Tag>
                <Tag size="sm" color="green">
                  Green
                </Tag>
                <Tag size="sm">Default</Tag>
                <Tag size="sm" color="yellow">
                  Yellow
                </Tag>
                <Tag size="sm" color="blue">
                  Blue
                </Tag>
                <Tag size="sm" color="sea">
                  Sea
                </Tag>
                <Tag size="sm" color="teal">
                  Teal
                </Tag>
                <Tag size="sm" color="orange">
                  Orange
                </Tag>
                <Tag size="sm" color="ink">
                  Ink
                </Tag>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Radio Tabs</h2>
          <div className="max-w-xs space-y-4">
            <Form onSubmit={() => {}}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field component={FFRadioTabs} name="title" label="Title">
                    <RadioTabs.Option value="m">Mr.</RadioTabs.Option>
                    <RadioTabs.Option value="f">Ms.</RadioTabs.Option>
                  </Field>
                </form>
              )}
            </Form>

            <RadioTabs
              items={[
                { value: 3, label: '3' },
                { value: 6, label: '6' },
                { value: 10, label: '10' },
                { value: 12, label: '12' },
              ]}
            />
          </div>
        </div>
        <div>
          <h2 className="subheading mb-3">Radio Cards</h2>
          <div className="max-w-xl space-y-4">
            <Form onSubmit={() => {}}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field component={FFRadioCards} name="title">
                    {[
                      {
                        term: 12,
                        apr: 0.0,
                        monthlyPayment: 2400,
                        customerTermOptions: [3, 6, 12],
                        popular: true,
                      },
                      {
                        term: 24,
                        apr: 0.099,
                        monthlyPayment: null,
                        customerTermOptions: [6, 12, 24],
                        popular: false,
                      },
                      {
                        term: 36,
                        apr: 0.12,
                        monthlyPayment: 1200,
                        customerTermOptions: [12, 24, 36],
                        popular: false,
                      },
                    ].map(item => {
                      const value = `${item.term}-${item.apr}`
                      return (
                        <RadioCards.Option key={value} value={value}>
                          {({ checked }) => (
                            <>
                              {item.popular && (
                                <Tag
                                  className="absolute -top-[10px] left-4 md:left-5"
                                  color="ink"
                                  size="sm"
                                >
                                  Polular
                                </Tag>
                              )}
                              <div className="flex items-start gap-x-2">
                                <RadioCards.OptionDot />

                                <div className="flex flex-1 items-center justify-between">
                                  <div className="space-y-2">
                                    <div>{item.term} months</div>
                                    <div
                                      className={`subheading inline-block rounded border bg-white px-2 py-1 ${
                                        item.apr > 0
                                          ? 'border-tabeo-ink-1 text-tabeo-ink-1'
                                          : 'border-tabeo-blue-3 text-tabeo-blue-3'
                                      }`}
                                    >
                                      {item.apr * 100}% APR
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-end gap-2">
                                    {item.monthlyPayment ? (
                                      <div className="flex items-baseline gap-1">
                                        {format(item.monthlyPayment)}
                                        <span className="caption">
                                          per month
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className={`h-6 w-24 animate-pulse rounded-full ${
                                          checked
                                            ? 'bg-tabeo-primary-5'
                                            : 'bg-tabeo-sky-2'
                                        }`}
                                      />
                                    )}
                                    <button
                                      type="button"
                                      className="anchor caption"
                                    >
                                      View details
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {checked && (
                                <div className="-m-4 mt-4 space-y-5 rounded-b border-t-2 border-tabeo-primary-3 bg-white p-5 md:-m-5 md:mt-5">
                                  <div className="caption intems-center flex gap-x-2 text-tabeo-ink-2">
                                    <InformationCircleIcon className="h-5 w-5" />
                                    Customer will receive option for{' '}
                                    {item.customerTermOptions.join(', ')} months
                                  </div>
                                  <div className="flex flex-col items-start gap-4 md:flex-row">
                                    <Field
                                      component={FFTextInput}
                                      name="email"
                                      label="Email address"
                                      placeholder="E.g. john@email.com"
                                      validate={[required, email]}
                                    />
                                    <Field
                                      component={FFTextInput}
                                      name="mobileNumber"
                                      label="Mobile number"
                                      leading={({
                                        className,
                                      }: {
                                        className: string
                                      }) => (
                                        <div className={className}>+44 (0)</div>
                                      )}
                                      validate={[required, email]}
                                      className="pl-[68px]"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </RadioCards.Option>
                      )
                    })}
                  </Field>
                </form>
              )}
            </Form>

            <RadioCards
              items={[
                { value: 3, label: '3' },
                { value: 6, label: '6' },
                { value: 10, label: '10' },
                { value: 12, label: '12' },
              ]}
            />
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Tooltip</h2>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:gap-y-5 xl:grid-cols-3">
            <Tooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.">
              <Tile>
                <Tile.Label>Tooltip.Title</Tile.Label>
                <Tile.Icon icon={ArrowRightIcon} />
              </Tile>
            </Tooltip>
            <Tooltip
              content={p => (
                <Tooltip.Title {...p.popper}>
                  <Tooltip.Arrow {...p.arrow} />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Tooltip.Title>
              )}
              placement="top"
            >
              <Tile>
                <Tile.Label>Tooltip.TitleWithArrow</Tile.Label>
                <Tile.Icon icon={ArrowRightIcon} />
              </Tile>
            </Tooltip>
            <Tooltip
              content={p => (
                <Tooltip.Popper
                  {...p.popper}
                  className="rounded border bg-tabeo-sky-3 p-4 shadow"
                >
                  <Tooltip.Arrow {...p.arrow} clipOverlap />
                  <span className="caption max-w-">Hello World!</span>
                </Tooltip.Popper>
              )}
            >
              <Tile>
                <Tile.Label>Tooltip.Custom</Tile.Label>
                <Tile.Icon icon={ArrowRightIcon} />
              </Tile>
            </Tooltip>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Breakpoints Hook</h2>
          <div className="max-w-xs rounded bg-tabeo-sky-2 p-4">
            <pre className="text-sm">
              {JSON.stringify(breakpoints, null, 2)}
            </pre>
          </div>
        </div>
        <div>
          <h2 className="subheading mb-1">Toast</h2>
          <Toaster />
          <div className="flex gap-4">
            <Button onClick={() => toast('Toast label')}>Make toast</Button>
            <Button onClick={() => toast.error('Toast label')}>
              Make error toast
            </Button>
            <Button onClick={() => toast.success('Toast label')}>
              Make success toast
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NntsComponents
