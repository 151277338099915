import { Button, Modal, NativeSelect, Prompt, Title, View } from '@tabeo/scarf'
import { FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { lengthRange, required } from '@tabeo/sharpei/utils/validations'
import { Basket, MerchantTreatment } from '@tabeo/ts-types'
import FormError from 'components/Form/FormError'
import MerchantTreatmentSelect from 'components/MerchantTreatmentSelect'
import { Field } from 'components/nnts/form/Field'
import useTreatments from 'pages/NNTS/components/treatment/useTreatments'
import { getCategoriesBySector } from 'pages/Settings/Treatments/constants'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

export type UpsertModalProps = {
  onSubmit: (values: {
    merchantTreatmentID?: number
    treatmentName?: string
    treatmentCategory?: string
  }) => Promise<void>
  data?: Basket
  isCliniciansAndTreatmentsEnabled?: boolean
  merchantSector: string
} & React.ComponentProps<typeof Modal>

const UpsertModal = React.forwardRef(
  (
    {
      onSubmit,
      data,
      isCliniciansAndTreatmentsEnabled,
      merchantSector,
      ...rest
    }: UpsertModalProps,
    ref: any
  ) => {
    const { t } = useTranslation()

    // This is used for "other" treatment category when C&T is on
    const treatmentCategories = [
      ...getCategoriesBySector(merchantSector).sort((a, b) =>
        a.localeCompare(b)
      ),
      t('Other'),
    ] as string[]

    // This is used when C&T is off
    const treatmentsByCategories = useTreatments()
    const isCurrentTreatmentNameOther =
      data?.treatmentName &&
      !treatmentsByCategories
        .flatMap(tc => tc.treatments)
        .includes(data?.treatmentName || '')

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          async function handleSubmit(values: {
            merchantTreatment: MerchantTreatment
            treatmentName?: string
            otherTreatmentName?: string
            treatmentCategory?: string
            otherTreatmentCategory?: string
          }) {
            try {
              await onSubmit({
                merchantTreatmentID: values.merchantTreatment?.id,
                treatmentName:
                  values.otherTreatmentName || values.treatmentName,
                treatmentCategory:
                  values.otherTreatmentCategory || values.treatmentCategory,
              })
              close()
            } catch (e) {
              return formError(e)
            }
          }

          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <Title mb={5}>
                {data?.treatmentName ? t('Edit treatment') : t('Add treatment')}
              </Title>
              <Form
                onSubmit={handleSubmit}
                initialValues={{
                  merchantTreatment: data?.merchantTreatment?.id
                    ? data.merchantTreatment
                    : undefined,
                  treatmentName:
                    isCliniciansAndTreatmentsEnabled ||
                    !isCurrentTreatmentNameOther
                      ? data?.treatmentName
                      : 'Other',
                  otherTreatmentName:
                    (!isCliniciansAndTreatmentsEnabled &&
                      isCurrentTreatmentNameOther) ||
                    (isCliniciansAndTreatmentsEnabled &&
                      data?.merchantTreatment?.treatment?.name === 'Other')
                      ? data?.treatmentName
                      : undefined,
                  treatmentCategory: data?.treatmentCategory,
                  otherTreatmentCategory:
                    isCliniciansAndTreatmentsEnabled &&
                    data?.merchantTreatment?.treatment?.name === 'Other'
                      ? data?.treatmentCategory
                      : undefined,
                }}
                keepDirtyOnReinitialize
                destroyOnUnregister
              >
                {({ handleSubmit, submitting, values }) => (
                  <form onSubmit={handleSubmit}>
                    {isCliniciansAndTreatmentsEnabled ? (
                      <>
                        <Field
                          name="merchantTreatment"
                          label={
                            <Prompt mb={1} fontWeight="semibold">
                              <Trans>Treatment</Trans>
                            </Prompt>
                          }
                          component={MerchantTreatmentSelect}
                          maxWidth="100%"
                          variant="outline"
                        />
                        {values.merchantTreatment?.treatment?.name ===
                          'Other' && (
                          <div className="mt-5 flex flex-col items-start gap-4">
                            <Field
                              name="otherTreatmentName"
                              label={t('Name')}
                              placeholder={t('Type name here...')}
                              component={FFTextInput}
                              validate={[required, lengthRange(4, 500)]}
                              clearable
                              autoFocus
                            />
                            <Field
                              name="otherTreatmentCategory"
                              label={t('Category')}
                              validate={[required]}
                              component={NativeSelect}
                              variant="outline-v2"
                              options={treatmentCategories.map(category => ({
                                value: category,
                                label: category,
                              }))}
                              maxWidth="100%"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <Field
                          name="treatmentName"
                          label={t('Treatment')}
                          placeholder={t('Select from the list')}
                          variant="outline-v2"
                          maxWidth="100%"
                          component={NativeSelect}
                          options={treatmentsByCategories
                            .flatMap(tc => tc.treatments)
                            .map(treatment => ({
                              value: treatment,
                              label: treatment,
                            }))}
                          validate={[required, lengthRange(4, 500)]}
                          clearable
                        />
                        {values.treatmentName === 'Other' && (
                          <Field
                            name="otherTreatmentName"
                            label={t('Name')}
                            placeholder={t('Type name here...')}
                            component={FFTextInput}
                            validate={[required, lengthRange(4, 500)]}
                            clearable
                            autoFocus
                            containerClassName="mt-5"
                          />
                        )}
                      </>
                    )}

                    <div className="mt-6">
                      <Button loading={submitting} mb={4} width="100%">
                        {t('Confirm')}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={close}
                        width="100%"
                      >
                        {t('Close')}
                      </Button>
                    </div>

                    <FormError />
                  </form>
                )}
              </Form>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default UpsertModal
