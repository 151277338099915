import {
  Button,
  ButtonGroup,
  Caption,
  Clickable,
  Flex,
  GridTable,
  GridTableRow,
  Heading,
  Icons,
  MultiActionButton,
  Subheading,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import { useMemo, useState } from 'react'
import { useMerchant } from 'resources/Merchant'
import { usePricingChangesContext } from '../../Context'
import illustration from '../../assets/2.png'
import { feeMatrix } from '../Step3/CollapsibleFeesTable'
import Cell from './Cell'
import HeaderCell from './HeaderCell'

function Step2() {
  const price = 1000_00

  const [showAll, setShowAll] = useState()

  const { next, remindMe, dontRemindMe } = usePricingChangesContext()
  const { data: merchant } = useMerchant()

  const { merchantRiskProfiles } = merchant
  const isRegulatedLoanCapable = merchant?.flags?.is_regulated_loan_capable

  const allOptions = useMemo(() => {
    const defaultRiskProfile = merchantRiskProfiles.find(
      ({ name }) => name === 'default'
    )

    return (
      defaultRiskProfile?.loanOptions.map(o => ({
        ...o,
        profile: defaultRiskProfile,
        newNetFee: feeMatrix?.[o.numberOfMonths]?.[o.interestRate]?.fee,
        oldNetFee: feeMatrix?.[o.numberOfMonths]?.[o.interestRate]?.oldFee,
      })) || []
    )
  }, [merchantRiskProfiles])

  const defaultOptions = useMemo(() => {
    const orderUR = [12, 10, 9, 6, 3]

    if (!isRegulatedLoanCapable) {
      return allOptions
        .sort(
          (a, b) =>
            orderUR.indexOf(a.numberOfMonths) -
            orderUR.indexOf(b.numberOfMonths)
        )
        .slice(0, 2)
    }

    const unregulatedOrdered = allOptions
      .filter(o => !o.isRegulated)
      .sort(
        (a, b) =>
          orderUR.indexOf(a.numberOfMonths) - orderUR.indexOf(b.numberOfMonths)
      )

    const orderR = [36, 24, 48, 60, 18, 12]
    const regulatedOrdered = allOptions
      .filter(o => o.isRegulated)
      .sort(
        (a, b) =>
          orderR.indexOf(a.numberOfMonths) - orderR.indexOf(b.numberOfMonths)
      )

    if (!unregulatedOrdered.length || !regulatedOrdered.length) {
      return [...unregulatedOrdered, ...regulatedOrdered].slice(0, 2)
    }

    return [unregulatedOrdered[0], regulatedOrdered[0]]
  }, [allOptions, isRegulatedLoanCapable])

  const options =
    allOptions.length > 3 ? (showAll ? allOptions : defaultOptions) : allOptions

  return (
    <View textAlign="center">
      <View
        as="img"
        src={illustration}
        width={['124px', '185px']}
        height={['120px', '180px']}
        mx="auto"
      />
      <Title mt={6}>Update on Subsidy Fees</Title>
      <Text mt={5} textAlign="center" fontSize={1}>
        Our new subsidy fees will be effective from <b>January 15th, 2024</b>.
      </Text>
      {!!options.length && (
        <>
          <Heading mt={6}>
            Illustrative example: {format(price, undefined, 0)} loan
          </Heading>
          <View
            overflow="auto"
            maxWidth="620px"
            border={1}
            borderRadius={1}
            mx="auto"
            mt={4}
          >
            <GridTable templateColumns="minmax(115px, auto) 60px 140px 140px 140px">
              <GridTableRow>
                <HeaderCell />
                <HeaderCell>
                  <Subheading color="blue.1" textAlign="right">
                    APR
                  </Subheading>
                </HeaderCell>
                <HeaderCell>
                  <Subheading color="blue.1" textAlign="right">
                    Old fee
                  </Subheading>
                </HeaderCell>
                <HeaderCell>
                  <Subheading color="blue.1" textAlign="right">
                    Change
                  </Subheading>
                </HeaderCell>
                <HeaderCell>
                  <Subheading color="blue.1" textAlign="right" mr={2}>
                    New fee
                  </Subheading>
                </HeaderCell>
              </GridTableRow>
              {options
                .sort((a, b) => a.numberOfMonths - b.numberOfMonths)
                .map((o, i) => {
                  const feeChange = Number(
                    (o.newNetFee - o.oldNetFee).toFixed(4)
                  )
                  const feeChangeAmount = price * feeChange

                  return (
                    <GridTableRow key={o.numberOfMonths + o.oldNetFee}>
                      <Cell odd={i % 2}>
                        <Caption color="ink.0" textAlign="left" ml={2}>
                          {o.numberOfMonths} months
                        </Caption>
                      </Cell>
                      <Cell odd={i % 2}>
                        <Caption color="ink.0" textAlign="right">
                          {(o.interestRate * 100).toFixed(1)}%
                        </Caption>
                      </Cell>
                      <Cell odd={i % 2}>
                        <Caption color="ink.0" textAlign="right">
                          {format(o.oldNetFee * price, undefined, 2)} (
                          {(o.oldNetFee * 100).toFixed(2)}
                          %)
                        </Caption>
                      </Cell>
                      <Cell odd={i % 2}>
                        <Caption
                          color="blue.2"
                          fontWeight="semibold"
                          textAlign="right"
                        >
                          {Number.isNaN(feeChangeAmount) ||
                          feeChangeAmount === 0 ? (
                            '-'
                          ) : (
                            <>
                              {feeChange > 0 ? '+' : '-'}
                              {format(Math.abs(feeChangeAmount))} (
                              {feeChange > 0 ? '+' : ''}
                              {(feeChange * 100).toFixed(2)}
                              %)
                            </>
                          )}
                        </Caption>
                      </Cell>
                      <Cell odd={i % 2}>
                        <Caption color="ink.0" textAlign="right" mr={2}>
                          {Number.isNaN(feeChangeAmount) ? (
                            '-'
                          ) : (
                            <>
                              {format(o.newNetFee * price)} (
                              {(o.newNetFee * 100).toFixed(2)}
                              %)
                            </>
                          )}
                        </Caption>
                      </Cell>
                    </GridTableRow>
                  )
                })}
            </GridTable>
          </View>
          {allOptions.length > 3 && (
            <Flex cursor="pointer" px={5} py={3} justifyContent="flex-start">
              <Clickable
                onClick={() => setShowAll(!showAll)}
                mt={2}
                className="!underline"
                justifyContent="center"
                alignItems="center"
                display="block"
                width="100%"
              >
                {!showAll
                  ? 'See all your current APR & term options'
                  : 'See less of your current APR & term options'}
                <Icons.ArrowRight
                  ml={1}
                  mb={1}
                  fill="primary.3"
                  display="inline-block"
                  transform={showAll ? `rotate(-90deg)` : `rotate(90deg)`}
                  transition="transform 200ms linear"
                />
              </Clickable>
            </Flex>
          )}
        </>
      )}
      <Text mt={6} textAlign="left" fontSize={1}>
        As a reminder, we support <b>a wide range of APR options</b> that allow
        you to share finance costs with patients.
      </Text>
      <ButtonGroup width="100%" mt={10}>
        <MultiActionButton
          width="100%"
          onClick={(e, { setOpen }) => {
            setOpen(o => !o)
          }}
          secondaryActions={[
            {
              label: 'Tomorrow',
              onClick: remindMe,
              textAlign: 'center',
            },
            {
              label: `Don't remind me again`,
              onClick: dontRemindMe,
              textAlign: 'center',
            },
          ]}
          right={
            <View
              px={2}
              py="7px"
              ml={5}
              borderLeft="1px solid"
              borderLeftColor="sky.0"
              cursor="pointer"
            >
              <Icons.ArrowDown fill="primary.3" width="20px" />
            </View>
          }
        >
          Remind me ...
        </MultiActionButton>
        <Button width="100%" onClick={next}>
          Next
        </Button>
      </ButtonGroup>
    </View>
  )
}

export default Step2
