import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { useMerchant } from '../../resources/Merchant'

const PrivateRoute = (props: RouteProps) => {
  const {
    data: { token, user },
  } = useAuth()
  const { pathname, search } = useLocation()

  const { data: merchant } = useMerchant(() => {
    if (!token) {
      throw new Error('Waiting for auth')
    }

    return undefined
  })

  const isAuthenticated = !!token

  if (!isAuthenticated) {
    return (
      <Redirect
        to={`/login?redirect=${encodeURIComponent(`${pathname}${search}`)}`}
      />
    )
  }
  if (!user || !merchant) {
    return null
  }
  if (merchant.state === 'onboarding' && pathname !== '/terms') {
    return <Redirect to="/signup" />
  }
  if (merchant.state === 'manual_review_failed') {
    return <Redirect to="/not-supported" />
  }
  // if (merchant.isNewTermsAndConditionsAvailable && pathname !== '/terms') {
  //   return <Redirect to="/accept-terms" />
  // }
  return (
    <Switch>
      {merchant.state === 'suspended' && (
        <Redirect from="/new-transaction" to="/suspended" />
      )}
      <Route {...props} />
    </Switch>
  )
}

export default PrivateRoute
