import { Container, Flex, Text as BaseText, themeGet, View } from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import LoadingScreen from 'components/LoadingScreen'
import Nav from 'components/Nav'
import moment from 'moment'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOffer } from 'resources/Offer'
import styled, { createGlobalStyle } from 'styled-components'
import tabeoLogo from '../../assets/logo.svg'
import { useAuth } from '../../resources/Auth'
import { useMerchant } from '../../resources/Merchant'
import Legal from './Legal'

const GlobalStyle = createGlobalStyle`
@media print {
  *{
    font-family: ${themeGet('font', 'arial')} !important;
    -webkit-print-color-adjust: exact;
  }
  #navbar {
    display: none;
  }
  .TrengoWidgetLauncher__iframe{
    display: none;
  }
  #print-container{
    background-color: white !important;
  }
}
`

const PrintContainer = styled(Container)`
  @media print {
    background-color: white !important;
  }
`

const Text = styled(BaseText)``

Text.defaultProps = {
  lineHeight: 'normal',
}

function PaymentOption({
  title,
  primaryColor,
  paymentPlan: {
    monthlyInvoicePaymentAmount,
    numberOfInvoices,
    interestRate,
    totalInterestPaymentAmount,
    totalPaymentAmount,
  },
  ...rest
}) {
  const finishDate = moment().add({ days: 7, months: numberOfInvoices })
  const { t } = useTranslation()
  return (
    <View {...rest}>
      <Flex bg="sky.0" height="24px" px={2}>
        {title}
      </Flex>
      <View bg="sky.1" p={2}>
        <Text fontWeight="semibold" color={primaryColor} mb={2}>
          {t(
            '{{monthlyInvoicePaymentAmount}} per month for {{numberOfInvoices}} months',
            {
              monthlyInvoicePaymentAmount: format(monthlyInvoicePaymentAmount),
              numberOfInvoices,
            }
          )}
        </Text>
        <Flex mb={2}>
          <Text fontSize="11px">
            {t('Cost of credit ({{interestRate}}% APR)', {
              interestRate: (interestRate * 100).toFixed(1),
            })}
          </Text>
          <Text fontSize="11px" align="right">
            {format(totalInterestPaymentAmount)}
          </Text>
        </Flex>
        <Flex mb={2}>
          <Text fontSize="11px">{t('Total to pay back')}</Text>
          <Text fontSize="11px" align="right">
            {format(totalPaymentAmount)}
          </Text>
        </Flex>
        <Text fontSize="8px" lineHeight="14px" color="ink.2">
          {t(
            'If you take this loan next week and make your payments on time, you’ll finish repaying in {{finishDate}}.',
            { finishDate: finishDate.format('MMM YYYY') }
          )}
        </Text>
      </View>
    </View>
  )
}

PaymentOption.propTypes = {
  title: PropTypes.element.isRequired,
  paymentPlan: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
}

function Print() {
  const [paymentPlans, setPaymentPlans] = React.useState(null)
  const [logoLoaded, setLogoLoaded] = React.useState(false)
  const { data, resource } = useOffer()
  const { t } = useTranslation()

  const { price, defaultRiskProfiles, shortLinks } = data || { metadata: {} }

  // Handle shortlink creation if needed
  const shortLinkRecreateCount = useRef(0)
  const validShortlink =
    shortLinks?.find(s => s.status !== 'expired')?.link || ''

  // Use a ref to prevent multiple calls to createNewOverviewShortlink
  const lock = useRef(false)

  useEffect(() => {
    // Valid shortlink found nothing to do
    if (validShortlink || shortLinkRecreateCount.current !== 0) {
      return
    }

    // Declare the async function inside the effect so we can wait for it to finish
    async function triggerCreate() {
      try {
        await resource.createNewOverviewShortlink()
        shortLinkRecreateCount.current += 1
      } catch (e) {
        Raven.captureException(e)
      } finally {
        lock.current = false
      }
    }

    // If the lock is already set, we are already creating a shortlink
    if (lock.current) return

    // Set the lock and trigger the creation
    lock.current = true
    triggerCreate()
  }, [validShortlink, resource, shortLinks?.length])

  const options = useMemo(
    () =>
      defaultRiskProfiles?.reduce(
        (acc, curr) => [
          ...acc,
          ...curr.loanOptions
            .filter(o => o.isAvailableForInitialPaymentPlan)
            .map(o => ({ ...o, riskProfile: curr })),
        ],
        []
      ),
    [defaultRiskProfiles]
  )

  useEffect(() => {
    async function getPaymentplans() {
      const payload = options.reduce((mem, item) => {
        mem[item.numberOfMonths] = {
          numberOfPayments: item.numberOfMonths,
          interestRate: item.interestRate,
          price,
          maxLoanAmount: price,
          firstPaymentAmount:
            item.riskProfile === 'tabeo_plus'
              ? parseInt(price * 0.2, 10)
              : undefined,
        }
        return mem
      }, {})

      const calculations = await api.post(`/utilities/amortize`, payload)

      const paymentPlans = options
        .map(i => ({
          ...calculations[i.numberOfMonths],
          value: i.numberOfMonths,
        }))
        .reverse()

      setPaymentPlans(paymentPlans)
    }
    if (options) {
      getPaymentplans()
    }
  }, [options, price])

  const handleLogoLoad = () => {
    setLogoLoaded(true)
  }

  const autoPrintLock = useRef(false)
  useEffect(() => {
    if (!!logoLoaded && !!paymentPlans && !autoPrintLock.current) {
      autoPrintLock.current = true
      setTimeout(() => window.print(), 500)
    }
  }, [logoLoaded, paymentPlans])

  const { data: merchant } = useMerchant()
  const {
    data: { user: merchantUser },
  } = useAuth()

  const {
    data: { isRetail },
  } = useMerchant()

  const {
    metadata: {
      merchantTheme: {
        logo = tabeoLogo,
        primaryColor = 'default.primary',
      } = {},
    },
  } = merchant

  const {
    id,
    title,
    consumerFirstName,
    consumerLastName,
    consumerEmail,
    consumer,
    createdAt,
  } = data || { metadata: {} }
  const { mobilePhone: consumerMobilePhone } = consumer || {}
  const validUntilDate = moment(createdAt).add(30, 'd')

  if (!data || !paymentPlans) {
    return <LoadingScreen />
  }

  const merchantTradingName = merchant.tradingName

  return (
    <>
      <GlobalStyle />
      <Nav />
      <PrintContainer
        display="flex"
        width="595px"
        wrapProps={{
          id: 'print-container',
          style: {
            height: 'auto',
            minHeight: '100%',
          },
        }}
      >
        {paymentPlans && (
          <View p="10px" flex="1" bg="white" pb="38px">
            <View display="flex" mb="20px">
              <View width="377px" mr="20px">
                <Flex mt="7px" mb="10px" alignItems="flex-start">
                  <Text fontSize="14px">
                    {t('Hi {{consumerFirstName}}', { consumerFirstName })}
                  </Text>
                  <Text fontSize="9px">{t('ID: {{id}}', { id })}</Text>
                </Flex>
                <Text lineHeight="19px" fontSize="11px" mb="7px">
                  {isRetail
                    ? t(
                        'Here are three possible options to help you split the cost of your purchase into easy monthly payments.'
                      )
                    : t(
                        'Here are three possible options to help you split the cost of your treatment into easy monthly payments.'
                      )}
                </Text>
                <Text lineHeight="19px" fontSize="11px" mb="20px">
                  {t(
                    'Applying online only takes a minute and won’t impact your credit score.'
                  )}
                </Text>
                <Flex alignItems="flex-start">
                  <Text width="258px" fontSize="19px">
                    {title}
                  </Text>
                  <View width="109px">
                    <Text fontSize="19px" textAlign="right" mb="3px">
                      {format(price)}
                    </Text>
                    <Text fontSize="10px" textAlign="right">
                      {moment(createdAt).format('DD MMM YYYY')}
                    </Text>
                  </View>
                </Flex>
              </View>
              <View width="178px" textAlign="left">
                <View
                  as="img"
                  src={logo}
                  maxWidth="178px"
                  maxHeight="60px"
                  mb="10px"
                  onLoad={handleLogoLoad}
                />
                <Text
                  fontSize="11px"
                  color={primaryColor}
                  fontWeight="semibold"
                >
                  {merchant.tradingName}
                </Text>
                <Text fontSize="11px" mb="10px">
                  {merchant.tradingAddress}
                </Text>
                <Text
                  fontSize="11px"
                  color={primaryColor}
                  fontWeight="semibold"
                  mb="5px"
                >
                  {merchant.phoneNumber}
                </Text>
                <Text
                  fontSize="11px"
                  color={primaryColor}
                  fontWeight="semibold"
                >
                  {merchant.email || merchantUser.user.email}
                </Text>
              </View>
            </View>
            <View display="flex" mb="20px">
              <View width="377px" mr="10px">
                {paymentPlans.map((p, i) => (
                  <PaymentOption
                    key={p.numberOfInvoices}
                    paymentPlan={p}
                    primaryColor={primaryColor}
                    title={
                      <>
                        <Text
                          colot="ink.2"
                          fontWeight="semibold"
                          fontSize="9px"
                        >
                          {t('OPTION {{index}}', { index: i + 1 })}
                        </Text>
                        {i === 0 ? (
                          <Text
                            px="6px"
                            py="2px"
                            bg="white"
                            fontSize="7px"
                            borderRadius="10px"
                            color={primaryColor}
                            fontWeight="semibold"
                          >
                            {t('MOST AFFORDABLE')}
                          </Text>
                        ) : i === 2 ? (
                          <Text
                            px="6px"
                            py="2px"
                            bg="white"
                            fontSize="7px"
                            borderRadius="10px"
                            color={primaryColor}
                            fontWeight="semibold"
                          >
                            {t('SHORTEST TERM')}
                          </Text>
                        ) : null}
                      </>
                    }
                    mb="10px"
                  />
                ))}
                {validShortlink && (
                  <Flex
                    bg={primaryColor}
                    height="36px"
                    justifyContent="center"
                    borderRadius={1}
                    mb="6px"
                  >
                    <Text
                      color="white"
                      fontSize="13px"
                      mr="12px"
                      fontWeight="semibold"
                    >
                      {t('Apply online at:')}
                    </Text>
                    <Text
                      bg="white"
                      px={5}
                      py="3px"
                      fontSize="13px"
                      borderRadius={1}
                      fontWeight="semibold"
                    >
                      {validShortlink}
                    </Text>
                  </Flex>
                )}
                <Text color="ink.2" fontSize="9px" data-no-track>
                  {t(
                    'This request was sent to {{consumerName}}, {{consumerEmail}}, {{consumerMobilePhone}} and will be valid until {{validUntilDate}}.',
                    {
                      consumerName: `${consumerFirstName} ${consumerLastName}`,
                      consumerEmail,
                      consumerMobilePhone,
                      validUntilDate: validUntilDate.format('Do MMM YYYY'),
                    }
                  )}
                </Text>
              </View>
              <View
                width="188px"
                textAlign="left"
                bg="sky.1"
                pt="5px"
                pl="5px"
                pr="3px"
              >
                <Text
                  fontSize="13px"
                  mb="5px"
                  fontWeight="semibold"
                  color="ink.2"
                >
                  {t('Frequently asked questions')}
                </Text>
                <Text
                  color={primaryColor}
                  fontWeight="semibold"
                  fontSize="10px"
                  mb={1}
                >
                  {t('How do repayments work?')}
                </Text>
                <Text fontSize="10px" mb="10px" lineHeight="normal">
                  {t(
                    'Managing your loan is simple, you can easily keep track of what you owe with your online account, and we always send you email reminders before each collection, so you’re never caught off guard with a payment.'
                  )}
                </Text>
                <Text
                  color={primaryColor}
                  fontWeight="semibold"
                  fontSize="10px"
                  mb={1}
                >
                  {t('What payment method can I use?')}
                </Text>
                <Text fontSize="10px" mb="10px" lineHeight="normal">
                  {t(
                    'We collect the monthly payments from your debit card, just as any online transaction, if you lose your card you can update this at any time for free.'
                  )}
                </Text>
                <Text
                  color={primaryColor}
                  fontWeight="semibold"
                  fontSize="10px"
                  mb={1}
                >
                  {t('When are payments collected?')}
                </Text>
                <Text fontSize="10px" mb="10px" lineHeight="normal">
                  <Trans>
                    We collect the first payment upfront, when{' '}
                    {{ merchantTradingName }} confirm the sale to us, this
                    usually happens when they book you in for your appointment.
                    <br />
                    <br />
                    Once your loan begins, we will collect a payment each month,
                    on a day of your choice. You can also change your payment
                    day for free.
                  </Trans>
                </Text>
                <Text
                  color={primaryColor}
                  fontWeight="semibold"
                  fontSize="10px"
                  mb={1}
                >
                  {t('Any more questions?')}
                </Text>
                <Text fontSize="10px" mb="10px" lineHeight="normal">
                  {t('Drop us an email:')}
                  <br />
                  <Text
                    as="a"
                    display="block"
                    fontSize="10px"
                    href="mailto:help@tabeo.co.uk"
                    color={primaryColor}
                  >
                    help@tabeo.co.uk
                  </Text>
                  {t('Or call us:')}
                  <br />
                  <Text
                    as="a"
                    display="block"
                    fontSize="10px"
                    href="tel:0808 281 2748"
                    color={primaryColor}
                  >
                    0808 281 2748
                  </Text>
                </Text>
              </View>
            </View>
            <View>
              <Text
                color={primaryColor}
                fontWeight="semibold"
                fontSize="10px"
                mb={1}
              >
                {t('Representative example')}
              </Text>
              <Text fontSize="10px" mb="10px">
                {t(
                  'A loan of {{price}} over {{numberOfInvoices}} months would cost you {{monthlyInvoicePaymentAmount}} per month at a representative {{interestRate}}% APR. The total amount of credit would be {{price}}, to which we would add {{totalInterestPaymentAmount}} ({{interestRate}}% interest p.a.). This means the total cost of your treatment after {{numberOfInvoices}} months would be {{totalPaymentAmount}}.',
                  {
                    price: format(price),
                    numberOfInvoices: paymentPlans[0].numberOfInvoices,
                    monthlyInvoicePaymentAmount: format(
                      paymentPlans[0].monthlyInvoicePaymentAmount
                    ),
                    interestRate: (paymentPlans[0].interestRate * 100).toFixed(
                      1
                    ),
                    totalInterestPaymentAmount: format(
                      paymentPlans[0].totalInterestPaymentAmount
                    ),
                    totalPaymentAmount: format(
                      paymentPlans[0].totalPaymentAmount
                    ),
                  }
                )}
              </Text>
              <Legal merchant={merchant} />
            </View>
          </View>
        )}
      </PrintContainer>
    </>
  )
}

export default Print
