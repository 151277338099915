import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import {
  View,
  TextInput,
  Button,
  Modal,
  Box,
  Subtitle,
  Text,
} from '@tabeo/scarf'

import Field from 'components/Form/Field'
import { required } from '@tabeo/sharpei/utils/validations'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

export const Container = styled(Box)`
  overflow: auto;
`

Container.defaultProps = {
  maxWidth: ['95%', '460px'],
  mx: 'auto',
  boxShadow: 2,
  p: [5, '40px'],
  textAlign: 'center',
}

class ApplyWithClientModal extends Component {
  render() {
    const { onSubmit, innerRef, t } = this.props
    return (
      <Modal ref={innerRef}>
        {({ isOpen, close, data }) =>
          isOpen && (
            <Container>
              <Subtitle mb={3}>{t('Apply with client')}</Subtitle>
              <Text mb={5}>
                {t('By continuing you confirm that you are with the client.')}
              </Text>
              <Form onSubmit={onSubmit} initialValues={data}>
                {({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="staffPerson"
                        variant="outline"
                        clearable
                        label={t('Staff member')}
                        placeholder={t('E.g. John Smith')}
                        component={TextInput}
                        validate={required}
                        containerProps={{
                          maxWidth: '320px',
                          mx: 'auto',
                          textAlign: 'left',
                        }}
                      />
                      <View mb={3}>
                        <Button type="submit" loading={submitting}>
                          {t('Confirm')}
                        </Button>
                      </View>
                      <View>
                        <Button
                          type="button"
                          variant="transparent"
                          onClick={close}
                        >
                          {t('Cancel')}
                        </Button>
                      </View>
                    </form>
                  )
                }}
              </Form>
            </Container>
          )
        }
      </Modal>
    )
  }
}

ApplyWithClientModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  t: PropTypes.func,
}

export default withTranslation()(ApplyWithClientModal)
