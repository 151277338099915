import {
  Button,
  CurrencyInput,
  DatePicker,
  Flex,
  Heading,
  NativeSelect,
  PhoneInput,
  Prompt,
  Radio,
  TextInput,
  View,
} from '@tabeo/scarf'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  lengthRange,
  mobilePhonePCA,
  name,
  phone,
  required,
  valueRange,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import { OTHER_VALUES } from 'components/MerchantTreatmentSelect'
import config from 'config'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import BillIntroduction from './BillIntroduction'
import normalize from './paymentFormNormalizer'
import { useTransactionTitles } from './transactionTitleOptionsBySector'

const lengthRangeFiveHundred = lengthRange(5, 100)

const { CURRENCY } = config

// TODO: check currency
const defaultInitialValues = {
  type: 'bill',
  currency: CURRENCY,
}

function BillForm({
  onFormChange,
  onSubmit,
  sector,
  successModal,
  initialValues,
  ...rest
}) {
  const { t } = useTranslation()
  const priceValidator = valueRange(
    1000,
    2000000,
    t(`Please specify an amount between {{min}} and {{max}}`, {
      min: format(1000),
      max: format(2000000),
    })
  )

  const localNormalize = values => {
    const { title } = values
    const otherTitle = OTHER_VALUES.includes(title)
      ? values.otherTitle
      : undefined

    return normalize({ ...values, title, otherTitle })
  }

  const handleSubmit = async values => {
    try {
      const normalizedValues = localNormalize(values)
      const transaction = await onSubmit({
        ...normalizedValues,
        title: normalizedValues.otherTitle || normalizedValues.title,
      })
      successModal.current.open({
        ...transaction,
        ...values,
      })
    } catch (e) {
      const consumerError = e.response.data.data.consumer?.[0]

      if (consumerError === 'Consumer is registered in a different country.') {
        return {
          formError: t('Consumer is registered in a different country.'),
        }
      }

      return formError(e)
    }
  }

  const titleOptions = useTransactionTitles('pay_now')
  const {
    data: { isRetail, isHealthcare },
  } = useMerchant()
  const titleLabel = isHealthcare
    ? t('Treatment')
    : isRetail
    ? t('Product')
    : t('Transaction name')
  return (
    <>
      <BillIntroduction />
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          amount: initialValues?.price,
          ...defaultInitialValues,
        }}
        destroyOnUnregister
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          form: { reset, resetFieldState, getRegisteredFields },
          submitting,
          valid,
          validating,
          values: { title },
        }) => {
          return (
            <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
              <form
                onSubmit={async e => {
                  const error = await handleSubmit(e)
                  if (!error && valid) {
                    reset()

                    // https://github.com/final-form/final-form/issues/317
                    // reset() doesn't clean some metadata
                    getRegisteredFields().forEach(field => {
                      // Handle conditional fields
                      if (getRegisteredFields().includes(field)) {
                        resetFieldState(field)
                      }
                    })
                  }
                }}
              >
                <FormSpy
                  onChange={({ values, valid }) => {
                    onFormChange({
                      valid,
                      values: {
                        ...localNormalize(values),
                        price: values.amount,
                      },
                    })
                  }}
                />
                <View p={[2, 7]} pb={[0, 2]} borderBottom={1}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Transaction')}
                  </Heading>

                  {titleOptions ? (
                    <>
                      <Field
                        name="title"
                        label={titleLabel}
                        component={NativeSelect}
                        options={titleOptions.map(o => ({
                          ...o,
                          value: o.label,
                        }))}
                        placeholder={t('Select from the list...')}
                        maxWidth="100%"
                        validate={required}
                      />
                      {title === t('Other') && (
                        <Field
                          name="otherTitle"
                          variant="outline"
                          clearable
                          placeholder={t('Please specify...')}
                          component={TextInput}
                          maxWidth="100%"
                          validate={[required, lengthRangeFiveHundred]}
                        />
                      )}
                    </>
                  ) : (
                    <Field
                      name="title"
                      variant="outline"
                      clearable
                      label={title}
                      placeholder={t('E.g.Item name')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, lengthRangeFiveHundred]}
                    />
                  )}

                  <Flex
                    mb={5}
                    flexDirection={['column', 'row']}
                    alignItems={['stretch', 'center']}
                    justifyContent={['flex-start', 'space-between']}
                  >
                    {['veterinary_services'].includes(sector) && (
                      <Field
                        containerProps={{ mb: [5, 0], mr: [0, 7], flex: 1 }}
                        label={t('Pet name')}
                        name="petName"
                        variant="outline"
                        clearable
                        placeholder={t('E.g. Spot')}
                        component={TextInput}
                        maxWidth="100%"
                        minWidth={['auto', '220px']}
                        validate={[required]}
                      />
                    )}
                    <Field
                      containerProps={{ mb: 0, flex: 1 }}
                      name="amount"
                      variant="outline"
                      clearable
                      maxWidth="100%"
                      label={
                        <Prompt mb={1} fontWeight="semibold">
                          <Trans>
                            Price{' '}
                            <Prompt display="inline" color="ink.2">
                              (including VAT)
                            </Prompt>
                          </Trans>
                        </Prompt>
                      }
                      component={CurrencyInput}
                      validate={[required, priceValidator]}
                    />
                  </Flex>

                  <Field
                    name="scheduledAt"
                    label={t('Payment date')}
                    component={DatePicker}
                    variant="outline"
                    clearable
                    width={['100%', '50%']}
                    alignPopupToTop
                    validate={required}
                    datePickerProps={{
                      modifiers: {
                        disabled: {
                          before: moment().add(1, 'day').toDate(),
                        },
                      },
                    }}
                    description={t(
                      `We'll charge the customer at 6am on this date.`
                    )}
                  />
                </View>

                <View p={[2, 7]} pb={[2, 6]}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Patient')}
                  </Heading>

                  <Field
                    name="consumerGender"
                    label={t('Title')}
                    validate={required}
                    component={Radio}
                    width={['100%', '50%']}
                    horizontal
                    height={40}
                    items={[
                      { label: t('Mr'), value: 'm' },
                      { label: t('Ms'), value: 'f' },
                    ]}
                    containerProps={{
                      mr: [0, 5],
                    }}
                    renderItem={p => <TabbedRadioItem {...p} />}
                  />
                  <Flex
                    alignItems={['stretch', 'flex-start']}
                    flexDirection={['column', 'row']}
                  >
                    <Field
                      name="consumerFirstName"
                      label={t('First name')}
                      variant="outline"
                      clearable
                      placeholder={t('E.g. John')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                      containerProps={{
                        flex: '1',
                        mr: [0, 5],
                        maxWidth: ['auto', '244px'],
                      }}
                    />
                    <Field
                      name="consumerLastName"
                      label={t('Last name')}
                      variant="outline"
                      clearable
                      placeholder={t('E.g. Smith')}
                      component={TextInput}
                      maxWidth="100%"
                      containerProps={{
                        flex: '1',
                      }}
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                    />
                  </Flex>

                  <Flex
                    alignItems={['stretch', 'flex-start']}
                    flexDirection={['column', 'row']}
                    mb={7}
                  >
                    <Field
                      data-no-track
                      name="consumerEmail"
                      variant="outline"
                      clearable
                      label={t('Email address')}
                      placeholder={t('E.g. johnsmith@email.co.uk')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, email, emailPCA]}
                      containerProps={{
                        flex: '1',
                        mr: [0, 5],
                        mb: 0,
                      }}
                    />

                    <Field
                      data-no-track
                      label={t('Mobile number')}
                      variant="outline"
                      clearable
                      component={PhoneInput}
                      name="consumerPhoneNumber"
                      maxWidth="100%"
                      validate={[required, phone, mobilePhonePCA]}
                      containerProps={{
                        flex: '1',
                        maxWidth: ['auto'],
                        mb: 0,
                      }}
                    />
                  </Flex>

                  <Button
                    width="100%"
                    loading={submitting}
                    disabled={validating}
                  >
                    {t('Send payment request')}
                  </Button>
                  <FormError />
                </View>
              </form>
            </View>
          )
        }}
      </Form>
    </>
  )
}

BillForm.propTypes = {
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  sector: PropTypes.string,
  successModal: PropTypes.object,
  initialValues: PropTypes.object,
}

export default BillForm
