import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import normalize from './normalizers/offer'
import { subResources } from './Offer'

class Offers extends Resource {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 15 * 1000,
    }
  }

  async fetch() {
    const subResources = formatSubresources(['paymentPlans.lender', 'merchant'])

    const { searchQuery, limit = 10, offset = 0, conditions = [] } = this.params

    const pagination = `&limit=${limit}&offset=${offset}`
    const jsonConditions = `&jsonConditions=${JSON.stringify(conditions)}`

    const endpoint = searchQuery
      ? `/merchant/offer-search?order=lastStateTransitionedAt:desc&searchPhrase=${encodeURIComponent(
          searchQuery
        )}&${subResources}`
      : `/merchant/offers?order=lastStateTransitionedAt:desc&${subResources}`

    const {
      data: { offers },
      totalCount,
    } = await api.get(`${endpoint}${jsonConditions}${pagination}`, {
      responseDataPath: 'data',
    })

    const normalizedOffers = offers.map(o => normalize(o))

    this.data = {
      totalCount,
      items: normalizedOffers,
    }
  }

  static create = async ({ leadId, ...payload }) => {
    const endpoint = leadId
      ? `/merchant/leads/${leadId}/offers`
      : '/merchant/offers'
    const {
      projections: {
        offer: { id },
      },
    } = await api.post(endpoint, payload)
    const { offer } = await api.get(`/merchant/offers/${id}?${subResources}`)
    return normalize(offer)
  }
}

export default Offers
export const useOffers = createResourceHook(Offers)
