import {
  Button,
  ButtonGroup,
  Icons,
  MultiActionButton,
  Text,
  Title,
  View,
} from '@tabeo/scarf'
import { useMerchant } from 'resources/Merchant'
import { usePricingChangesContext } from '../../Context'
import illustration from '../../assets/3.png'
import CollapsibleFeesTable from './CollapsibleFeesTable'
import ExampleOptions from './ExampleOptions'

function Step3() {
  const { remindMe, dontRemindMe } = usePricingChangesContext()
  const { data: merchant } = useMerchant()

  const isRegulatedLoanCapable = merchant?.flags?.is_regulated_loan_capable

  return (
    <View textAlign="center">
      <View
        as="img"
        src={illustration}
        width={['154px', '232px']}
        height={['105px', '160px']}
        mx="auto"
      />
      <Title mt={6}>Interest bearing options</Title>
      <Text mt={5} textAlign="left" fontSize={1}>
        Consumers continue to feel the impact of rising housing costs, which is
        not captured well in CPI inflation data. Excess savings built up during
        Covid should be exhausted by now. As a result, it&apos;s important to
        offer more longer term options. Introducing longer term loan options
        with non zero APR is one way to cap/reduce subsidy fees.
      </Text>
      <ExampleOptions mt={6} />
      <CollapsibleFeesTable mt={6} />
      {!isRegulatedLoanCapable ? (
        <>
          <View
            border={1}
            borderColor="green.4"
            bg="green.6"
            p={2}
            borderRadius={1}
            mt={6}
          >
            <Text color="green.0" textAlign="left" fontSize={1}>
              You need to be{' '}
              <b>authorised by the Financial Conduct Authority (FCA)</b> to
              offer finance for {'>'} 12 months or APR {'>'} 0%.
              <br />
              <br />
              <b>Tabeo can help you</b>, join our FCA network and offer longer
              term options to your patients.
            </Text>
          </View>
          <Text mt={6} textAlign="left" fontSize={1}>
            We created a secure form for you to let us know if you want to learn
            more about the costs and benefits of being Tabeo&apos;s Introducer
            Appointed Representative (IAR), or to apply for a limited permission
            license.
          </Text>
        </>
      ) : (
        <Text mt={6} textAlign="left" fontSize={1}>
          We have created a secure form so you can{' '}
          <b>indicate your preferences</b> with the new interest bearing options
          easily. Don&apos;t worry, you can change them quickly any time.
        </Text>
      )}
      <ButtonGroup width="100%" mt={10}>
        <MultiActionButton
          width="100%"
          onClick={(e, { setOpen }) => {
            setOpen(o => !o)
          }}
          secondaryActions={[
            {
              label: 'Tomorrow',
              onClick: remindMe,
              textAlign: 'center',
            },
            {
              label: `Don't remind me again`,
              onClick: dontRemindMe,
              textAlign: 'center',
            },
          ]}
          right={
            <View
              px={2}
              py="7px"
              ml={5}
              borderLeft="1px solid"
              borderLeftColor="sky.0"
              cursor="pointer"
            >
              <Icons.ArrowDown fill="primary.3" width="20px" />
            </View>
          }
        >
          Remind me ...
        </MultiActionButton>

        {isRegulatedLoanCapable ? (
          <Button
            as="a"
            href="https://docs.google.com/forms/d/e/1FAIpQLScg_xjBEWSZd6_zPcyM7JT_EhuDJlOOb0FyAoCiVBP4ZhJj5A/viewform"
            target="_blank"
            rel="noopener noreferrer"
            width="100%"
          >
            Update finance options
          </Button>
        ) : (
          <Button
            as="a"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfEj88OJusb81lVF49GjYdQkj4P1fCurHnF42mu2YRSILXYxA/viewform"
            target="_blank"
            rel="noopener noreferrer"
            width="100%"
          >
            Join our FCA network
          </Button>
        )}
      </ButtonGroup>
    </View>
  )
}

export default Step3
