"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  createResourceCache: () => createResourceCache_default,
  createResourceHook: () => createResourceHook_default,
  default: () => src_default
});
module.exports = __toCommonJS(src_exports);

// src/resource.ts
var Resource = class {
  constructor(params, config, data) {
    this._params = {};
    this._config = {
      refreshInterval: 15e3,
      isEnabled: true
    };
    this._listeners = {};
    this._listenerLastID = 0;
    this._poller = 0;
    this.isPolling = false;
    this.isSyncing = false;
    var _a, _b;
    this.fetch = this.fetch.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    const initialData = data != null ? data : (_a = this.getInitialData) == null ? void 0 : _a.call(this);
    this._data = initialData;
    this._config = {
      ...this._config,
      ...(_b = this.getInitialConfig) == null ? void 0 : _b.call(this),
      ...config
    };
    if (params) {
      this._params = {
        ...params
      };
    }
    queueMicrotask(() => {
      this.sync();
    });
  }
  get _listenersArray() {
    return Object.keys(this._listeners);
  }
  async handleFocusChange() {
    const hasFocus = document.hasFocus();
    if (hasFocus) {
      try {
        await this.sync();
      } finally {
        if (this._listenersArray.length) {
          this.startPolling();
        }
      }
    } else {
      this.stopPolling();
    }
  }
  set params(params) {
    if (this._params) {
      this._params = {
        ...this._params,
        ...params
      };
    } else {
      this._params = params;
    }
    this.sync();
  }
  get params() {
    return this._params;
  }
  set config(config) {
    this._config = {
      ...this._config,
      ...config
    };
    this.sync();
    if (this._listenersArray.length) {
      this.startPolling();
    }
  }
  get config() {
    return this._config;
  }
  async sync() {
    if (!this.isSyncing && this.config.isEnabled) {
      try {
        this.isSyncing = true;
        this.triggerEvent("sync", true);
        await this.fetch();
        this.triggerEvent("sync", false);
      } catch (e) {
        console.error(
          `An error happend during fetch in ${this.constructor.name} resource`,
          e
        );
        if (e instanceof Error) {
          this.triggerEvent("error", e);
        } else {
          this.triggerEvent("error", new Error("Error during sync"));
        }
      }
      this.isSyncing = false;
    }
  }
  startPolling() {
    if (this.isPolling) {
      this.stopPolling();
    }
    if (this.config.refreshInterval && this.config.refreshInterval > 0) {
      this.isPolling = true;
      this._poller = window.setInterval(async () => {
        try {
          await this.sync();
        } catch (e) {
          if (e instanceof Error) {
            this.triggerEvent("error", e);
          } else {
            this.triggerEvent("error", new Error("Error during sync"));
          }
        }
      }, this.config.refreshInterval);
    }
  }
  stopPolling() {
    clearInterval(this._poller);
    this.isPolling = false;
  }
  listenerUnsubscriber(listenerID) {
    delete this._listeners[listenerID];
    if (!this._listenersArray.length) {
      this.stopPolling();
      window.removeEventListener("focus", this.handleFocusChange);
      window.removeEventListener("blur", this.handleFocusChange);
    }
  }
  on(topic, callback) {
    this._listenerLastID += 1;
    const listenerID = this._listenerLastID;
    this._listeners[this._listenerLastID] = { topic, callback };
    if (this._listenersArray.length === 1) {
      this.startPolling();
      window.addEventListener("focus", this.handleFocusChange);
      window.addEventListener("blur", this.handleFocusChange);
    }
    this.sync();
    return () => this.listenerUnsubscriber(String(listenerID));
  }
  triggerEvent(topic, event) {
    this._listenersArray.forEach((listenerID) => {
      const currentListener = this._listeners[listenerID];
      if (currentListener && topic === currentListener.topic)
        currentListener.callback(event);
    });
  }
  getListenersByTopic(topic) {
    return this._listenersArray.filter(
      (key) => this._listeners[key].topic === topic
    );
  }
  set data(data) {
    const updatedData = data;
    const newStateHash = JSON.stringify(updatedData);
    const oldStateHash = JSON.stringify(this.data);
    if (newStateHash !== oldStateHash) {
      this._data = updatedData;
      if (this.data !== void 0) {
        this.triggerEvent("update", this.data);
      }
    }
  }
  get data() {
    return this._data;
  }
  flush(initialState2) {
    this._data = initialState2;
    if (this.data !== void 0) {
      this.triggerEvent("update", this.data);
    }
  }
};
var resource_default = Resource;

// src/createResourceCache.ts
function createResourceCache(Resource2) {
  const cache = {};
  return function getInstanceFromCache(params, config) {
    const paramsHash = JSON.stringify(params);
    if (!cache[paramsHash]) {
      cache[paramsHash] = new Resource2(params, config);
    } else {
      const previousConfig = cache[paramsHash].config;
      const updatedConfig = { ...previousConfig, ...config };
      if (JSON.stringify(previousConfig) !== JSON.stringify(updatedConfig)) {
        cache[paramsHash].config = updatedConfig;
      }
    }
    return cache[paramsHash];
  };
}
var createResourceCache_default = createResourceCache;

// src/createResourceHook.ts
var import_react = require("react");
var import_react_dom = require("react-dom");
var initialState = {
  error: null,
  data: null
};
function createResourceHook(Resource2) {
  const getInstanceFromCache = createResourceCache_default(Resource2);
  function useResource(paramsObjectOrFunction, config) {
    var _a;
    const resourceInstance = (0, import_react.useRef)();
    let params = null;
    let hasMissingParamDependencies = false;
    try {
      if (paramsObjectOrFunction instanceof Function) {
        params = paramsObjectOrFunction();
      } else {
        params = paramsObjectOrFunction;
      }
      resourceInstance.current = getInstanceFromCache(params, config);
    } catch (e) {
      hasMissingParamDependencies = true;
    }
    const [state, setState] = (0, import_react.useState)(initialState);
    const paramsHash = JSON.stringify(params);
    const configHash = JSON.stringify(config);
    (0, import_react.useEffect)(() => {
      var _a2, _b, _c, _d;
      if (!hasMissingParamDependencies) {
        resourceInstance.current = getInstanceFromCache(params, config);
        const ubsubscribeErrors = (_b = (_a2 = resourceInstance.current) == null ? void 0 : _a2.on) == null ? void 0 : _b.call(
          _a2,
          "error",
          (error) => {
            (0, import_react_dom.flushSync)(() => {
              setState({ error, data: null });
            });
          }
        );
        const ubsubscribeUpdates = (_d = (_c = resourceInstance.current) == null ? void 0 : _c.on) == null ? void 0 : _d.call(
          _c,
          "update",
          (data) => {
            (0, import_react_dom.flushSync)(() => {
              setState({ error: null, data });
            });
          }
        );
        return () => {
          ubsubscribeErrors == null ? void 0 : ubsubscribeErrors();
          ubsubscribeUpdates == null ? void 0 : ubsubscribeUpdates();
        };
      }
    }, [paramsHash, configHash]);
    return {
      error: state.error,
      data: (_a = resourceInstance.current) == null ? void 0 : _a.data,
      resource: resourceInstance.current || null
    };
  }
  return useResource;
}
var createResourceHook_default = createResourceHook;

// src/index.ts
var src_default = resource_default;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  createResourceCache,
  createResourceHook
});
