import {
  Button,
  Flex,
  Heading,
  NativeSelect,
  Radio,
  TextInput,
  View,
} from '@tabeo/scarf'
import formError from '@tabeo/sharpei/utils/formError'
import {
  email,
  emailPCA,
  name,
  required,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import TabbedRadioItem from 'components/Form/TabbedRadioItem'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const normalizeFormValues = ({ petName, ...values }, sector) => {
  if (sector === 'veterinary_services' && petName) {
    return {
      ...values,
      patient: {
        patientType: 'pet',
        pet: {
          name: petName,
        },
      },
    }
  }
  return values
}

class PlanForm extends Component {
  handleSubmit = async ({ planId, ...values }) => {
    const { onSubmit, successModal, sector, t } = this.props
    try {
      const normalizedValues = normalizeFormValues(values, sector)
      const subscription = await onSubmit(planId, normalizedValues)
      successModal.current.open({
        ...subscription,
        ...values,
        type: 'plan',
      })
    } catch (e) {
      const consumerError = e.response.data.data.consumer?.[0]

      if (consumerError === 'Consumer is registered in a different country.') {
        return {
          formError: t('Consumer is registered in a different country.'),
        }
      }

      return formError(e)
    }
  }

  render() {
    const {
      onFormChange,
      onSubmit,
      initialValues,
      plans,
      sector,
      successModal,
      t,
      ...rest
    } = this.props

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          form: { reset, resetFieldState, getRegisteredFields },
          submitting,
          validating,
          valid,
        }) => {
          const options = plans
            ? plans.map(p => ({ label: p.title, value: p.id }))
            : []
          return (
            <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
              <form
                onSubmit={async e => {
                  try {
                    await handleSubmit(e)
                    if (valid) {
                      reset()

                      // https://github.com/final-form/final-form/issues/317
                      // reset() doesn't clean some metadata
                      getRegisteredFields().forEach(field => {
                        // Handle conditional fields
                        if (getRegisteredFields().includes(field)) {
                          resetFieldState(field)
                        }
                      })
                    }
                  } catch (e) {
                    return e
                  }
                }}
              >
                <FormSpy onChange={onFormChange} />
                <View p={[2, 7]} pb={[0, 2]} borderBottom={1}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Transaction')}
                  </Heading>
                  {['veterinary_services'].includes(sector) && (
                    <Field
                      name="petName"
                      variant="outline"
                      clearable
                      label={t('Pet name (optional)')}
                      placeholder={t('E.g. Spot')}
                      component={TextInput}
                      maxWidth="100%"
                      format={v => (v ? v.capitalize() : '')}
                    />
                  )}
                  <Field
                    name="planId"
                    label={t('Care plan')}
                    component={NativeSelect}
                    maxWidth="100%"
                    validate={required}
                    options={options}
                  />
                </View>
                <View p={[2, 7]} pb={[2, 6]}>
                  <Heading fontWeight="semibold" mb={5}>
                    {t('Patient')}
                  </Heading>

                  <Field
                    name="consumerGender"
                    label={t('Title')}
                    validate={required}
                    width={['100%', '50%']}
                    component={Radio}
                    horizontal
                    items={[
                      { label: t('Mr'), value: 'm' },
                      { label: t('Ms'), value: 'f' },
                    ]}
                    containerProps={{
                      mr: [0, 5],
                    }}
                    renderItem={p => <TabbedRadioItem {...p} />}
                  />
                  <Flex
                    alignItems={['stretch', 'flex-start']}
                    flexDirection={['column', 'row']}
                  >
                    <Field
                      name="consumerFirstName"
                      label={t('First name')}
                      variant="outline"
                      clearable
                      placeholder={t('E.g. John')}
                      component={TextInput}
                      maxWidth="100%"
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                      containerProps={{
                        flex: '1',
                        mr: [0, 5],
                        maxWidth: ['auto', '244px'],
                      }}
                    />
                    <Field
                      name="consumerLastName"
                      label={t('Last name')}
                      variant="outline"
                      clearable
                      placeholder={t('E.g. Smith')}
                      component={TextInput}
                      maxWidth="100%"
                      containerProps={{
                        flex: '1',
                      }}
                      validate={[required, name]}
                      format={v => (v ? v.capitalize() : '')}
                    />
                  </Flex>
                  <Field
                    data-no-track
                    name="consumerEmail"
                    variant="outline"
                    clearable
                    label={t('Email address')}
                    placeholder={t('E.g. johnsmith@email.co.uk')}
                    component={TextInput}
                    maxWidth="100%"
                    validate={[required, email, emailPCA]}
                  />
                  <Button
                    width="100%"
                    loading={submitting}
                    disabled={validating}
                  >
                    {t('Invite to Care Plan')}
                  </Button>
                  <FormError />
                </View>
              </form>
            </View>
          )
        }}
      </Form>
    )
  }
}

PlanForm.propTypes = {
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  plans: PropTypes.array,
  initialValues: PropTypes.object,
  sector: PropTypes.string,
  successModal: PropTypes.object,
  t: PropTypes.func,
}

export default function (props) {
  const { t } = useTranslation()
  return <PlanForm t={t} {...props} />
}
