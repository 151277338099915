import { usePromise } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TickGreen } from 'components/checkout-payment/assets/tick-green.svg'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import moment from 'moment'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

export default function Active() {
  const { t } = useTranslation()
  const { data } = useSubscriptionV2()

  const [triggerCancel, cancelState] = usePromise(() => {})

  return (
    <ActionCard>
      <ActionCard.Header>
        <TickGreen className="h-6 w-6" />
        {t('Membership active')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The membership is active. The next payment will be collected on {{date}}.',
          {
            date: moment(data?.subscription?.nextPaymentDate).format(
              'DD MMM YYYY'
            ),
          }
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <Button
          size="sm"
          variant="secondary"
          onClick={triggerCancel}
          loading={cancelState.pending}
        >
          {t('Cancel membership')}
        </Button>
      </ActionCard.Actions>
      {cancelState.error && (
        <ActionCard.Error>
          {t('There was an error, please try again later.')}
        </ActionCard.Error>
      )}
    </ActionCard>
  )
}
