import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import formatSubresources from '@tabeo/sharpei/utils/formatSubresources'
import { useRouteMatch } from 'react-router-dom'
import { NormalizedTerminalPayment } from 'types/NormalizedTerminalPayment'
import normalize from './normalizers/terminalPayment'

interface Params {
  id: string | number
}

const subResources = formatSubresources([
  'basket.patient',
  'basket.merchantTreatment.treatment',
  'basket.merchantClinician.clinician',
  'charges',
  'events',
  'payouts',
])

class TerminalPayment extends Resource<NormalizedTerminalPayment, Params> {
  getInitialConfig() {
    return {
      refreshInterval: 30 * 1000,
    }
  }

  async fetch() {
    const transfersConditions = {
      type: 'merchant_proceed',
      owner_type: 'terminal_payments',
      owner_id: this.params.id,
      status: 'succeeded',
    }

    const [{ terminalPayment }, { transfers }, { refunds }] = await Promise.all(
      [
        api.get(
          `/merchant/terminal-payments/${this.params.id}?${subResources}`
        ),
        api.get(
          `/merchant/transfers?jsonConditions=${JSON.stringify(
            transfersConditions
          )}&subResource=payout`
        ),
        api.get(
          `/merchant/terminal-payments/${
            this.params.id
          }/refunds?&order=createdAt:desc&limit=1000&${formatSubresources([
            'requester',
            'approver',
            'decliner',
            'canceler',
          ])}`
        ),
      ]
    )

    this.data = normalize({
      ...terminalPayment,
      transfers,
      refunds,
    })

    return this.data
  }

  capturePayment = async () => {
    const response = await api.post(
      `/merchant/terminal-payments/${this.params.id}/capture`
    )

    await this.fetch()
    return response
  }

  assignReaderToTerminalPayment = async (readerSerialNumber: string) => {
    const response = await api.put(
      `/merchant/terminal-payments/${this.params.id}/reader`,
      { readerSerialNumber }
    )

    await this.fetch()
    return response
  }

  cancel = async () => {
    const response = await api.put(
      `/merchant/terminal-payments/${this.params.id}/cancel`
    )

    await this.fetch()
    return response
  }

  sendReceipt = async (payload: { email?: string; phoneNumber?: string }) => {
    const response = await api.put(
      `/merchant/terminal-payments/${this.params.id}/receipt/send`,
      payload
    )

    await this.fetch()
    return response
  }

  assignPatientToBasket = async (patientId: number) => {
    if (!this.data) {
      throw new Error('TerminalPayment data is null')
    }

    const response = await api.put(`/merchant/baskets/${this.data.basket.id}`, {
      patientID: patientId,
    })

    await this.fetch()
    return response
  }

  requestRefund = async (payload: {
    amount: number
    reasonType: string
    reasonNote?: string
  }) => {
    const response = await api.post(
      `/merchant/terminal-payments/${this.params.id}/refund-requests`,
      payload
    )

    await this.fetch()
    return response
  }
}

export default TerminalPayment
export const useResource = createResourceHook(TerminalPayment)

export function useTerminalPayment(p?: Params | (() => Params)) {
  const match = useRouteMatch<{
    terminalPaymentId?: string
  }>({
    path: ['/terminal-payments/:terminalPaymentId'],
  })

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const { id } = params || {}
  const {
    params: { terminalPaymentId },
  } = match || { params: {} }

  const terminalPaymentIdParam = id || terminalPaymentId

  return useResource(() => {
    if (!terminalPaymentIdParam) {
      throw new Error('TerminalPayment ID is null')
    }
    return {
      id: Number.isNaN(Number(terminalPaymentIdParam))
        ? terminalPaymentIdParam
        : +terminalPaymentIdParam,
    }
  })
}
