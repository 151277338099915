import {
  Button,
  CurrencyInput,
  NativeSelect,
  Radio,
  Textarea,
  Title,
  View,
} from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import {
  lengthRange,
  required,
  valueRange,
} from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMerchant } from 'resources/Merchant'
import { useRefundFlow } from './RefundFlowContext'

async function calculator(offerId, retentionAmount) {
  const response = await api.post(
    `/merchant/offers/${offerId}/calculate-refund-attributes`,
    { retentionAmount }
  )
  return response
}

function Details({ ...rest }) {
  const { next, close, transaction, flowState, setFlowState } = useRefundFlow()

  const { id, consumerGender, revisedPrice, paymentPlan } = transaction

  const {
    data: { isRetail },
  } = useMerchant()

  const { t } = useTranslation()

  async function handleSubmit({ retentionAmount = 0, ...values }) {
    const {
      originalPrice,
      refundAmount,
      revisedPrice,
      merchantFeeDiscount,
      lateRefundFee,
      originalVar,
      varDiscount,
      revisedVar,
      executionAmount,
      finalNetProceeds,
      currentNetProceeds,
    } = await calculator(id, retentionAmount)

    setFlowState(s => ({
      ...s,
      ...values,
      originalPrice,
      refundAmount,
      revisedPrice,
      merchantFeeDiscount,
      lateRefundFee,
      originalVar,
      varDiscount,
      revisedVar,
      executionAmount,
      retentionAmount,
      finalNetProceeds,
      currentNetProceeds,
    }))
    next()
  }

  const retentionReasonValidator = lengthRange(
    10,
    255,
    t('The length of the reason cannot be less than 10 characters')
  )
  const remainingTransactionValue = revisedPrice || paymentPlan?.principalAmount

  const retentionAmountValidator = React.useMemo(
    () =>
      valueRange(
        500,
        remainingTransactionValue,
        t(`The retainment amount cannot be more than {{amount}}`, {
          amount: format(remainingTransactionValue),
        })
      ),
    [remainingTransactionValue, t]
  )

  return (
    <View {...rest}>
      <Title mb={3}>{t('Refund transaction')}</Title>
      <Form
        onSubmit={handleSubmit}
        initialValues={flowState}
        destroyOnUnregister
      >
        {({ handleSubmit, submitting, values: { isPartial } }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="refundReason"
              label={t('Why are you refunding this transaction?')}
              component={NativeSelect}
              options={[
                {
                  label:
                    consumerGender === 'm'
                      ? t(`Customer changed his mind`)
                      : t(`Customer changed her mind`),
                  value: 'mind_change',
                },
                isRetail
                  ? false
                  : {
                      label: t('Cost of treatment was lower than expected'),
                      value: 'lower_cost',
                    },
                {
                  label: isRetail
                    ? t('Customer was unhappy with the purchase')
                    : t('Customer was unhappy with the treatment'),
                  value: 'dissatisfaction',
                },
              ].filter(Boolean)}
              validate={required}
              maxWidth="100%"
            />
            {!isRetail && (
              <Field
                name="isPartial"
                label={t(
                  `Have you provided part of the treatment or have incurred in any cost that you wish to retain?`
                )}
                component={Radio}
                items={[
                  { label: t('Yes'), value: true },
                  { label: t('No'), value: false },
                ]}
                validate={required}
              />
            )}
            {isPartial && (
              <>
                <Field
                  name="retentionAmount"
                  label={
                    isRetail ? (
                      <span>
                        <Trans>
                          The current product cost is{' '}
                          {{ amount: format(remainingTransactionValue) }}.{' '}
                          <br />
                          What is the new product cost?
                        </Trans>
                      </span>
                    ) : (
                      <span>
                        <Trans>
                          The current treatment cost is{' '}
                          {{ amount: format(remainingTransactionValue) }}.{' '}
                          <br />
                          What is the new treatment cost?
                        </Trans>
                      </span>
                    )
                  }
                  component={CurrencyInput}
                  maxWidth="100%"
                  validate={[required, retentionAmountValidator]}
                />
                <Field
                  label={t('Why do you wish to keep this amount?')}
                  name="retentionReason"
                  component={Textarea}
                  placeholder={t(
                    'E.g. The practice ordered... which costed...'
                  )}
                  description={t(
                    `This will be shown to the customer as explanation`
                  )}
                  width="100%"
                  display="block"
                  validate={[required, retentionReasonValidator]}
                />
              </>
            )}
            <Button loading={submitting} width="100%" mb={4}>
              {t('Preview refund bill')}
            </Button>
            <Button
              type="button"
              onClick={close}
              variant="secondary"
              width="100%"
            >
              {t('Close')}
            </Button>
            <FormError />
          </form>
        )}
      </Form>
    </View>
  )
}

export default Details
